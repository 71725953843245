import React, { useState,useEffect } from "react";
import {
  makeStyles,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  Paper,
  Button,
  TextField,
  Divider,
  Grid,
  IconButton,
  Drawer,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Header from "../../components/navbar/index";
import withAllContexts from "../../hocs/withAllContexts";
import { v4 as uuidv4 } from "uuid";
import { LanguageContext } from "../../contexts";
import { MTableEditRow } from "material-table";
import axios  from 'axios';
import config from '../../config';
import Alert from "../../components/alert";
import { ReactTransliterate } from "react-transliterate";
import "react-transliterate/dist/index.css";
import { isDefinitionNode } from "graphql";
import Dialog from "../../components/dialog/index";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#f4f4f5",
  },
  container: {
    padding: "25px",
  },
  tableRow: {
    height: 50,
  },
  tabAlign: {
    display: "flex",
    paddingBottom: "16px",
  },
  drawerContainer: {
    padding: "18px",
  },
  drawerContent: {
    paddingTop: "16px",
  },
}));

const taste = [
  { no: "1", name: "lane", active: "active" },
  { no: "2", name: "dane", active: "active" },
  { no: "3", name: "pane", active: "active" },
  { no: "4", name: "fane", active: "active" },
];

// const drawerPref = [
//   { titles: "Title", placeholder: "User", label: "User", minWidth: "50px" },
//   {
//     titles: "English",
//     placeholder: "Enter your text",
//     label: "language",
//     minWidth: "180px",
//   },
//   {
//     titles: "French",
//     placeholder: "Enter your text",
//     label: "Enter your text",
//     minWidth: "180px",
//   },
//   {
//     titles: "Spanish",
//     placeholder: "Enter your text",
//     label: "Enter your text",
//     minWidth: "180px",
//   },
// ];

function MessageCatalog(props) {
  const classes = useStyles();
  const messageHeaderName = "QDM";

  const [fieldChange, setFieldChange] = useState({});
  const [languageDetails, setLanguageDetails] = useState([]);
  const [isdrawerOpen, setDraweropen] = useState(false);
  const [isEditOpen, setEditOpen] = useState({
    field: "",
    isOpen: false,
  });
  const [drawerPref,setDrawerPref ] = useState([
      { titles: "Title", placeholder: "User", label: "User", minWidth: "50px",code:'' }
  ])
  const [editedLanguage, setEditedLanguage] = useState({});
  const [ messageAlert,setMessageAlert ]  = useState({
      isopen: false,
      snackmsg: "client save successfully",
      snacktype: "success",
   
  })
  const editOpen = (languages, index) => {
    // console.log("current index", index);
    debugger;
    setEditOpen((prev) => {
      return { ...prev, isOpen: true, field: languages };
    });
  };
  const editClose = (languages) => {
    setEditOpen((prev) => {
      return { ...prev, isOpen: false, field: "" };
    });
  };
  useEffect(()=>{
    if (window.location.search && window.location.search.length > 0) {
      let params = new URLSearchParams(window.location.search);
      localStorage.setItem("metadataId", params.get("metaDataId"));
      localStorage.setItem("MessageCatalogid", params.get("MessageCatalogid"));
      localStorage.setItem("projectName", params.get("projectName"));
    }
    getlanguage();
    getMessageLog();
  },[])
  const getlanguage = async() =>{
    await axios
      .post(`${config.entity_api_url}/api/read_documents`, {
        db_name: `${config.database}`,
        entity: `${config.language_entity}`,
        filter: `${config.language_entity}.languageid == '${localStorage.getItem('MessageCatalogid')}'`,
        return_fields: `${config.language_entity}`
      })
      .then((response) => {
        if (
          response.data.result[response.data.result.length - 1].attributes
        ) {
          let selectLanguage = response.data.result[response.data.result.length - 1].attributes;
          debugger;
          // let generateJSON = selectLanguage.map((data,index)=>{
          //   return {titles: data.language,placeholder: "Enter your text",label: "language", minWidth: "180px",code:data.code}}
          //   );
          let generateJSON =[
            {...selectLanguage,titles: selectLanguage.language,placeholder: "Enter your text",label: "language", minWidth: "180px",code:selectLanguage.code}
          ]
            setDrawerPref([...drawerPref,...generateJSON])
          closeLoader();

        }
      })
      .catch((err) => { });
  }
  const getMessageLog = async() =>{
    await axios
      .post(`${config.entity_api_url}/api/read_documents`, {
        db_name: `${config.database}`,
        entity: `${config.message_catalog_entity}`,
        filter: `${config.message_catalog_entity}.messageCatalogid == '${localStorage.getItem('MessageCatalogid')}'`,
        return_fields: `${config.message_catalog_entity}`
      })
      .then((response) => {
        debugger;
        if (
          response.data.result[response.data.result.length - 1].message_catalog.attributes
        ) {
          let message = response.data.result[response.data.result.length - 1].message_catalog.attributes;
          setLanguageDetails(message);
        }
      })
      .catch((err) => { });
  }
  const drawerClosing = () => {
    setDraweropen(false);
  };
  const drawerOpen = () => {
    let fields = {

    }
    drawerPref.map(field=>{
      fields[field.titles]=''
    })
      setFieldChange({
        ...fieldChange,
        ...fields

      });
    setDraweropen(true);
  };

  const fieldChanging = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    setFieldChange((pre) => {
      return {
        ...pre,
        id: uuidv4(),
        [fieldName]: fieldValue,
      };
    });
  };

  const onAdd = () => {
    let countList =  Object.values(fieldChange).filter(data=>data.length>0)
   if(countList.length<2){
    setMessageAlert({
      isopen:true,
      snackmsg: "Please Fill the  Data",
      snacktype: "info",
    })
    return;
   }

    setLanguageDetails((pre) => {
      return [...pre, fieldChange];
    });
    setDraweropen(false);
  };
  const [deleteState,setDeleteState] = useState({});
  const [deleteDrawer,setDeleteDrawer] = useState(false);
  const deleteRow = (languages) => {

    const newRow = languageDetails.filter((itm) => itm.id !== languages.id);
    setLanguageDetails(newRow);
    handleDeleteDrawerClose()
  };
  const handleDelete = (lang)=>{
    setDeleteState(lang);
    setDeleteDrawer(true);

  }
  const handleDeleteDrawerClose = ()=>{
    setDeleteDrawer(false)
  }
  const onEditing = (e) => {
    const editname = e.target.name;
    const editvalue = e.target.value;
    // setEditedLanguage((preve) => {
    //   return {
    //     ...preve,
    //     [editname]: editvalue,
    //   };
    // });
    let editField = isEditOpen;
    editField.field[editname] = editvalue;
    setEditOpen({
      ...editField
    });
  };

  console.log("editedfield", editedLanguage);

  const onEditAdd = () => {
    debugger;
    languageDetails[
      languageDetails
        .map((x, i) => [i, x])
        .filter((x) => x[1] == isEditOpen.field)[0][0]
    ] = isEditOpen.field;
    debugger;
    // saveLanguageDetails();
    setEditOpen((prev) => {
      return { ...prev, isOpen: false, field: "" };
    });
  };
  const saveLanguageDetails = async() =>{
    debugger;
    let dataList = {
      db_name: config.database,
      entity: config.message_catalog_entity,
      is_metadata: false,
      projectname: localStorage.getItem('projectName'),
      doc: {
        metadataId: localStorage.getItem('metadataId'),
        messageCatalogid:localStorage.getItem('MessageCatalogid'),
        projectname: localStorage.getItem('projectName'),
        attributes: languageDetails,
      },
    };
   
    let data = JSON.stringify([dataList]);
    var configfile = {
      method: "post",
      url: `${config.entity_api_url}/api/upsert_document`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios;
    axios(configfile)
      .then((response) => {
        setMessageAlert({
          isopen:true,
          snackmsg: "message catalog save successfully",
          snacktype: "success",
        })
       debugger;
      })
      .catch((err) => {
      });
  }
  const closeSnackBar = () =>{
    setMessageAlert({
      ...messageAlert,
      isopen:false
    })
  }
  const drawerClose = ()=>{
    setEditOpen({
      ...isEditOpen,
      isOpen:false
    })
    
  }
  const  closeIsDrawerOpen = () =>{
    setDraweropen(false);
  }
  const [loader,setLoader  ] = useState(true);
  const  closeLoader = () =>{
    setLoader(false);
  }
  return (
    <div className={classes.root}>
      <Dialog 
      open={loader}
      handleClose={()=>closeLoader()}
      transprant={true}
      component={
        <CircularProgress color="secondary" style={{margin: "auto"}}/>
      }
      />
      <Dialog
              open={deleteDrawer}
              handleClose={()=>handleDeleteDrawerClose()}
              component={
                <div>
                  <div style={{ padding: "10px",textAlign: "center"}}>
                  <Typography className={classes.title} style={{fontSize:'1.2rem'}}>Are you sure, you want to delete?</Typography>

                  </div>
                  <div style={{    textAlign: "center",marginTop: "15px",padding: "10px"}}>
                  <Button variant="contained" style={{marginRight: "10px"}} onClick={()=>handleDeleteDrawerClose()}>Cancel</Button>
                  <Button variant="contained" color="primary" onClick={()=>deleteRow(deleteState)}>
                           Submit
                   </Button>
                  </div>
                </div>
              }
            />
      {messageAlert.isopen && (
          <Alert
            severity={messageAlert.snacktype}
            open={messageAlert.isopen}
            vertical={"top"}
            horizontal={"right"}
            msg={messageAlert.snackmsg}
            onclose={() => closeSnackBar()}
          />
        )}
      <Header headerName={messageHeaderName}></Header>
      <div className={classes.container}>
        <div className={classes.tabAlign}>
          <Typography style={{ fontSize: "24px", flexGrow: 1 }}>
            Message Catalog
          </Typography>
          <Button
            onClick={drawerOpen}
            variant="contained"
            style={{ marginRight: "10px" }}
          >
            add
          </Button>
      
          <Button color="primary" variant="contained" onClick={()=>saveLanguageDetails()}>
            Save
          </Button>
        </div>
        <Paper>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {drawerPref.map((itm) => (
                    <TableCell style={{ width: itm.minWidth }}>
                      {itm.titles}
                    </TableCell>
                  ))}
                  <TableCell align='left'>Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody >
                {
                  languageDetails?.length<=0?<>
                    <TableRow height="200">
                      <div style={{    position: "absolute",width: "92%", textAlign: "center", marginTop: "30px"}}>
                        <span>No Data Found</span>
                        </div>
                    </TableRow>
                  </>:''
                }
                {languageDetails.map((languages, index) => (
                  <TableRow className={classes.tableRow}>
                    {
                      Object.keys(languages).map((key)=>(
                        key!=='id'?
                         <TableCell>{languages[key]}</TableCell>:""
                      ))
                    }
                    {/* <TableCell>{languages.Title}</TableCell>
                    <TableCell>{languages.English}</TableCell>
                    <TableCell>{languages.French}</TableCell>
                    <TableCell>{languages.Spanish}</TableCell> */}
                    <TableCell align='left' style={{display: "flex",    padding: "0px !important"}}>
                      <div style={{    marginLeft: "-18px"}}>
                        <IconButton onClick={(e) => editOpen(languages, index)}>
                          <EditIcon></EditIcon>
                        </IconButton>
                        <IconButton onClick={(e) => handleDelete(languages)}>
                          <HighlightOffIcon></HighlightOffIcon>
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>

      {/* ----------------------------------- Drawer ----------------------------------------------------- */}
      <Drawer open={isdrawerOpen} onClose={()=>closeIsDrawerOpen()} anchor="right">
        <div className={classes.drawerContainer}>
          <Typography style={{ fontSize: "18px",    fontWeight: "500",padding: "10px 0px"}}>
            Add language preferences
          </Typography>
          <Divider></Divider>
          {drawerPref.map((item) => (
            <div className={classes.drawerContent}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <div style={{ display: "flex", padding: "10px 0 0 0" }}>
                    <Typography>{item.titles}</Typography>
                    <div style={{ flexGrow: 1 }}></div>
                    <Typography>:</Typography>
                  </div>
                </Grid>
                <Grid item xs={8} style={{margin: "auto"}}>
                  {/* <TextField
                    size="small"
                    variant="outlined"
                    placeholder={item.placeholder}
                    label={item.placeholder}
                    name={item.titles}
                    onChange={fieldChanging}
                    fullWidth
                  ></TextField> */}
                    <ReactTransliterate
                      value={fieldChange[item.titles]}
                      onChange={(e)=>{e.target.name=item.titles;fieldChanging(e)}}
                      lang={item.code}
                      offsetY={5}
                      style={{    boxShadow: "0 0 12px 0 #eee",
                        color: "rgba(0,0,0,.8)", fontSize: "1rem" ,border: "1px solid rgb(156 156 156)",
                        padding: "6px",outline: "none"
                      }}
                    />
                </Grid>
              </Grid>
            </div>
          ))}
          <div
            style={{
              width: "80%",
              margin: "auto",
              marginTop: "15px",
              textAlign: "end",
            }}
          >
            <Button
              variant="contained"
              onClick={drawerClosing}
              style={{ marginRight: "10px" }}
            >
              Close
            </Button>
            <Button variant="contained" color="primary" onClick={onAdd}>
              Add
            </Button>
          </div>
        </div>
      </Drawer>

      {/* ============================ Edit Drawer ===================================== */}
      <Drawer open={isEditOpen.isOpen} onClose={()=>drawerClose()} anchor="right">
        <div className={classes.drawerContainer}>
          <Typography style={{ fontSize: "18px",fontWeight: "500",padding: "10px 0px" }}>
            Edit language preferences
          </Typography>
          <Divider></Divider>
          {drawerPref.map((item) => (
            <div className={classes.drawerContent}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <div style={{ display: "flex", padding: "10px 0 0 0" }}>
                    <Typography>{item.titles}</Typography>
                    <div style={{ flexGrow: 1 }}></div>
                    <Typography>:</Typography>
                  </div>
                </Grid>
                <Grid item xs={8} style={{margin: "auto"}}>
                  {/* <TextField
                    size="small"
                    variant="outlined"
                    placeholder={item.placeholder}
                    label={item.placeholder}
                    name={item.titles}
                    onChange={onEditing}
                    value={isEditOpen.field[item.titles]}
                    fullWidth
                  ></TextField> */}
                     <ReactTransliterate
                      value={fieldChange[item.titles]}
                      onChange={(e)=>{e.target.name=item.titles;onEditing(e)}}
                      lang={item.code}
                      offsetY={5}
                      style={{    boxShadow: "0 0 12px 0 #eee",
                      color: "rgba(0,0,0,.8)", fontSize: "1rem" ,border: "1px solid rgb(156 156 156)",
                      padding: "6px",outline: "none"
                    }}
                    />
                </Grid>
              </Grid>
            </div>
          ))}
          <div
            style={{
              width: "80%",
              margin: "auto",
              marginTop: "15px",
              textAlign: "end",
            }}
          >
            <Button
              variant="contained"
              
              onClick={editClose}
              style={{ marginRight: "10px" }}
            >
              Close
            </Button>
            <Button variant="contained" color="primary" onClick={onEditAdd}>
              Update
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default withAllContexts(MessageCatalog);
