import React from 'react'
import { withRouter } from 'react-router-dom'
import {
  withStyles,
  Grid,
  TextField,
  Typography,
  Card,
  CardHeader,
  Avatar,
  Button
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Dialog from '../dialog/index'
// style
const styles = theme => ({
  root: {
    background: '#f4f4f5',
    height: '90vh'
  },
  addbtn: {
    color: '#fff',
    background: '#131947',
    borderRadius: 10,
    cursor: 'pointer',

    fontSize: 14,
    alignItems: 'center',
    padding: '6px 13px 8px 6px',
    display: 'flex',
    width: 'fit-content',
    float: 'right',
    margin: '24px 48px 6px',
    [theme.breakpoints.only('xs')]: {
        margin:"24px 22px 6px"
      },
  },
  dialogbox: {
    padding: 24
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    padding: '0px 0px 16px',
    letterSpacing: 1,
    borderBottom: '1px solid #090c23'
  },
  savebtns: {
    color: '#fff',
    background: '#131947',
    borderRadius: 10,
    cursor: 'pointer',

    fontSize: 14,
    alignItems: 'center',
    padding: '10px 14px',
    width: 'fit-content',
    float: 'right'
  },
  card: {
    margin: '12px 20px',
    borderRadius: 8,
    boxShadow: '0px 5px 23px #00000014',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    '& .MuiCardHeader-title': {
      fontSize: 15,
      fontWeight: 500
    }
  },
  choosebtn: {
    background: '#f4f4f5',
    borderRadius: 6,
    padding: '3px 3px',
    margin: '5px 0px',
    textTransform: 'capitalize',
    fontSize: 13,
    letterSpacing: 1,
    opacity: 0.8,
    '& a': {
      textDecoration: 'unset',
      color: '#222222bf'
    }
  }
})
// end

class AinqaComponent extends React.Component {
  // state
  constructor (props) {
    super(props)
    this.state = {
      addproject: false,
      addprojectvalue: '',
      data: [
        {
          title: 'Ainqa and Chorizo Paella data'
        }
      ]
    }
  }
  // end

  addProject = () => {
    //   add project modal open
    const state = this.state
    this.setState({
      addproject: !state.addproject
    })
  }
  onSubmit = () => {
    //   add project name list
    const state = this.state
    state.data.push({
      title: state.addprojectvalue
    })
    this.setState({
      ...state,
      addproject: !state.addproject
    })
  }
  handleCange = (n, v) => {
    //   add project handle change
    const state = this.state
    this.setState({
      ...state,
      [n]: v
    })
  }
  render () {
    const { classes } = this.props

    const { addproject, data } = this.state

    return (
      <div className={classes.root}>
        <div className={classes.addbtn} onClick={() => this.addProject()}>
          {' '}
          <AddIcon /> Add Project
        </div>

        {/* dialog box */}
        <Dialog
          open={addproject}
          handleClose={() => this.addProject()}
          component={
            <div>
              <Typography className={classes.title}>Add Project</Typography>
              <div className={classes.dialogbox}>
                <TextField
                  variant='outlined'
                  placeholder='Project name'
                  label='Project name'
                  fullWidth={true}
                  onChange={e =>
                    this.handleCange('addprojectvalue', e.target.value)
                  }
                />
              </div>
              <div className={classes.savebtns} onClick={() => this.onSubmit()}>
                {' '}
                Submit{' '}
              </div>
            </div>
          }
        />
        {/* end */}

        {/* card listing */}
        <Grid container>
          {data?.map(v => {
            return (
              <Grid item xs={12} sm={6}>
                <Card className={classes.card}>
                  <CardHeader
                    avatar={
                      <Avatar aria-label='recipe' className={classes.avatar}>
                        A
                      </Avatar>
                    }
                    title={v?.title}
                    subheader={
                      <Button className={classes.choosebtn}>
                        <a
                          href='https://www.ainqa.com/'
                          rel='noopener noreferrer'
                          target='_blank'
                        >
                          ainqa
                        </a>
                      </Button>
                    }
                  />
                </Card>
              </Grid>
            )
          })}
        </Grid>
        {/* end */}
      </div>
    )
  }
}

export default withStyles(styles)(withRouter(AinqaComponent))
