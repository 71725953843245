export const themes = {
    default:'default',
    dark:'dark'
} 

export const alertProps = {
    vertical:{
        top:'top',
        bottom:'bottom',
    },
    horizontal:{
        left:'left',
        right:'right',
        center:'center'
    },
    severity:{
        success:'success',
        error:'error',
        warning:'warning',
        info:'info',
    }
}

export const DrawerProps = {
    direction: {
        top: "top",
        bottom: "bottom",
        left: "left",
        right: "right",
    },
    variant: {
        permanent: "permanent",
        persistent: "persistent",
        temporary: "temporary",
    },
};

export const localStorageKeys = {
    auth_token:'auth_token'
}

export const netWorkCallMethods = {
    get:'GET',
    post:'POST',
    put:'PUT',
    delete:'DELETE',
    update:'UPDATE'
}