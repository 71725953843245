import dotenv from 'dotenv';
dotenv.config();

let config = {};
config.entity_api_url = `${process.env.REACT_APP_SECURITY}://${process.env.REACT_APP_ENTITY_DOMAIN}`;
config.language_entity = process.env.REACT_APP_LANGUAGE_ENTITY;
config.database = process.env.REACT_APP_DATABASE;
config.userEntity  = process.env.REACT_APP_USERS;
config.clientEntity  = process.env.REACT_APP_CLIENT;
config.message_catalog_entity = process.env.REACT_APP_MESSAGE_CATALOG;
config.api_url = process.env.REACT_APP_API_ENDPOINT;
config.graphql = process.env.REACT_APP_GQL_ENDPOINT;
config.socket = process.env.REACT_APP_SOCKET;
config.qpas_entity = process.env.REACT_APP_QPAS_ENTITY;
config.Strategic_Modeler=process.env.REACT_APP_Strategic_Modeler//167.71.228.116:9015
config.Enterprise_Modeler=process.env.REACT_APP_Enterprise_Modeler//167.71.228.116:9000
config.QPAS_Builder=process.env.REACT_APP_QPAS_Builder//167.71.228.116:9010
config.Entity_Designer=process.env.REACT_APP_Entity_Designer//167.71.228.116:9000
config.UI_Custom_Form_Designer=process.env.REACT_APP_UI_Custom_Form_Designer//167.71.228.116:9001
config.UI_Form_Template_Builder=process.env.REACT_APP_UI_Form_Template_Builder//ippc.netlify.app
config.UI_Auto_Renderer=process.env.REACT_APP_UI_Auto_Renderer//167.71.228.116:9001
config.UI_Reports_Builder=process.env.REACT_APP_UI_Reports_Builder//167.71.228.116:9001
config.UI_Dashboard_Builder=process.env.REACT_APP_UI_Dashboard_Builder//167.71.228.116:9001
config.UI_Flow_Builder=process.env.REACT_APP_UI_Flow_Builder//167.71.228.116:9001
config.Rule_Builder=process.env.REACT_APP_Rule_Builder//13.71.3.63:7001
config.Service_Service_Flow_Builder=process.env.REACT_APP_Service_Service_Flow_Builder//drive.google.com
config.Job_Scheduler=process.env.REACT_APP_Job_Scheduler
config.Job_Monitor=process.env.REACT_APP_Job_Monitor
config.Task_Document_Worfklow_Builder=process.env.REACT_APP_Task_Document_Worfklow_Builder
config.Chatbot_Workflow_Builder=process.env.REACT_APP_Chatbot_Workflow_Builder//neurolozix.com
config.Integrated_Test_Engine=process.env.REACT_APP_Integrated_Test_Engine//managedcare.scanner.testocean.in
config.Alerts_Hub=process.env.REACT_APP_Alerts_Hub//164.52.208.253
config.AVC_Video_Conferencing=process.env.REACT_APP_AVC_Video_Conferencing//ainqa-telehealth.live
config.Runtime_Admin_Console=process.env.REACT_APP_Runtime_Admin_Console//52.172.177.66:8529
config.ELK_Dashboard=process.env.REACT_APP_ELK_Dashboard//13.71.3.63:8072
config.Promethus_Dashboard=process.env.REACT_APP_Promethus_Dashboard//13.71.3.63:9091
config.NiFi=process.env.REACT_APP_NiFi//167.71.228.116:9012
config.Message_Catalog=process.env.REACT_APP_Message_Catalog//167.71.228.116:9005
config.AinqaRefine=process.env.REACT_APP_AINQA_REFINE//164.52.212.67:3333
config.GitSupportTool=process.env.REACT_APP_GIT_SUPPORT_TOOL//http://164.52.212.67:3000/
config.Datalake=process.env.REACT_APP_DATA_LAKE//http://164.52.211.118:9870/
config.ETL=process.env.REACT_APP_ETL//http://164.52.212.67:3000/
config.NoteBook=process.env.REACT_APP_NOTEBOOK//164.52.212.67:3333
config.Scheduler=process.env.REACT_APP_SCHEDULER//http://164.52.212.67:3000/
config.MLOPS=process.env.REACT_APP_MLOPS//http://164.52.212.67:3000/
config.DMPLogin=process.env.REACT_APP_AINQA_DMP_LOGIN

export default config;