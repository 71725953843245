import React from 'react'
import { Grid, withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import routes from './../../router/routes'
import Header from '../../components/navbar/index'
import EntityComponent from '../../components/entityComponent/index'
import AinqaComponent from '../../components/ainqaProject/index'
// import { localStorageKeys } from './../../utils'

const styles = theme => ({})

class Home extends React.Component {
  // {*----------page inside  child components-----------*}
    constructor(props){
      super(props);
      this.state={
        headerName:"Data Management Platform"
      }
    }
    handleHeaderName = (name)=>{
      this.setState({
        headerName:name
      })
    }
  giveMeComponentToRender = () => {
    if (
      this.props.location.pathname === routes.home ||
      this.props.match.path === routes.home
    ) {
      return <EntityComponent {...this.props} handleHeaderName={this.handleHeaderName}/>
    } else if (
      this.props.location.pathname === routes.ainqa ||
      this.props.match.path === routes.ainqa
    ) {
      return <AinqaComponent {...this.props} />
    }
  }

  // componentDidMount() {
  //     if (!localStorage.getItem(localStorageKeys.auth_token)) {
  //         this.props.history.push(routes.login);
  //     }
  // }

  render () {
    const { classes } = this.props

    return (
      <Grid container justify='center' alignItems='center'>
        <Grid item xs={12} className={classes.header}>
          {/* Header component here */}
          <Header headerName={this.state.headerName}/>
          {/* end */}
        </Grid>

        <Grid item xs={12}>
          {/* // {*----------inside child components-----------*} */}
          {this.giveMeComponentToRender()}
          {/* end */}
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(withRouter(Home))
