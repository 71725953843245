import React from "react";
import { BalancePayloadContext,DrawerContext,LanguageContext } from "../contexts";


const withAllContexts = (Component) => (props) => {
  const balancePayload = React.useContext(BalancePayloadContext);
  const drawer = React.useContext(DrawerContext);
  const lang = React.useContext(LanguageContext)
  return (
    <Component {...props} balancePayload={balancePayload} drawer={drawer} lang={lang}>
      {props.children}
    </Component>
  );
};

export default withAllContexts;