import {
  Typography,
  Paper,
  makeStyles,
  IconButton,
  InputBase,
  FormControl,
  InputLabel,
  Select,
  Divider,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  ListItem,
  List,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import withAllContexts from "../../hocs/withAllContexts";
import {LanguageContext} from '../../contexts'

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: "25px",
  },
  leftSection: {
    width: 350,
  },
  paperEdit: {
    height: 470,
    overflow: "auto",
    paddingBottom: "100px",
  },
  rightSection: {
    width: "100%",
    paddingLeft: "15px",
  },
  innerRightSec: {
    display: "flex",
    padding: "15px",
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: "relative",
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: "20px",
    borderColor: "#eee",
    marginRight: theme.spacing(1),
    marginLeft: 0,
    width: "50%",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#A7A4A4",
  },
  inputRoot: {
    color: "primary",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "70%",
  },
  formControl: {
    maxWidth: "100%",
    width: "100%",
    borderRadius: "20px",
  },
  lablePadding: {
    padding: "10px 14.4px",
  },
  labelFormControl: {
    top: "-10px",
  },
  shirnk: {
    display: "none",
  },
  outline: {
    paddingRight: "12  0px !important",
  },
  outlineBorder: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "20px",
      borderColor: "#eee",
    },
  },
  search2: {
    position: "relative",
    borderRadius: "4px",
    border: "2px solid #eee",
    marginRight: theme.spacing(1),
    marginLeft: 0,
    width: "100%",
  },
  btnLable: {
    margin: "0 250px",
  },
  container: {
    maxHeight: 440,
  },
  tablecell: {
    padding: "0px 20px !important",
  },
  selectedList: {
    backgroundColor: "#45149b !important",
    color: "#fff",
    width: "92%",
    margin: "0 10px",
  },
  addDialog: {
    "& .MuiDialog-paperWidthSm": {
      width: "400px",
    },
  },
  selectedRow: {
    backgroundColor: "#eee !important",
  },
}));

const list = ["test1", "test2", "test3", "test4", "test5"];

function Masters(props) {
  const classes = useStyles();
  const [age, setAge] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedList, setSelectedList] = useState("test1");
  const [nameInput, setnameInput] = useState({});
  const [names, setnames] = useState([]);
  const [tableIndex, settableIndex] = useState();
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState({
    isOpen: false,
    id: "",
  });
  const [editedName, setEditedName] = useState();
  const [isDelete, setisDelete] = useState({
    isOpen: false,
    id: "",
  });
  // let {list} = React.useContext(LanguageContext)
  // console.log("created language", list);

  const handleListItemClick = (event, index, item) => {
    setSelectedIndex(index);
    setSelectedList(item);
   
  };

  const AddNewName = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    setnameInput((preValue) => {
      return {
        ...preValue,
        selectedList,
        [inputName]: inputValue,
        id: uuidv4(),
      };
    });
  };

  console.log("list data", names);
  console.log("l", nameInput);


  const onAdd = () => {
    setnames((pre) => {
      return [...pre, nameInput];
    });
    setAddOpen(false);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleRowSelect = (event, index) => {
    settableIndex(index);
  };

  const handleAddOpen = () => {
    setAddOpen(true);
  };
  const handleAddClose = () => {
    setAddOpen(false);
  };

  const handleEditOpen = (tabitem) => {
    console.log("ed",tabitem.name);
    setEditOpen((prev) => {
      return { ...prev, isOpen: true, id: tabitem };
    });
  };
  const handleEditClose = () => {
    setEditOpen((prev) => {
      return { ...prev, isOpen: false, id: "" };
    });
  };

  const onEditChange = (e) => {
    setEditedName(e.target.value);
  };

  const onEdit = () => {
    const newEdit = names.filter((edit) => edit.id === editOpen.id.id);
    newEdit.map((edited) => (edited.name = editedName));
    setEditOpen((prev) => {
      return { ...prev, isOpen: false, id: "" };
    });
  };

  const deleteOpen = (tabitem) => {
    setisDelete((prev) => {
      return { ...prev, isOpen: true, id: tabitem };
    });
  };

  const deleteClose = (tabitem) => {
    setisDelete((prev) => {
      return { ...prev, isOpen: false, id: tabitem };
    });
  };

  const deleteCell = () => {
    const newRow = names.filter((del) => del.id !== isDelete.id.id);
    setnames(newRow);
    setisDelete(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.leftSection}>
        <Paper className={classes.paperEdit}>
          {list.map((item, index) => (
            <div>
              <List>
                <ListItem
                  selected={selectedIndex === index}
                  button
                  onClick={(event) => handleListItemClick(event, index, item)}
                  classes={{ selected: classes.selectedList }}
                >
                  {" "}
                  {item}{" "}
                </ListItem>
              </List>
            </div>
          ))}
        </Paper>
      </div>

      {/* --------------------------------- Right Section ------------------------------------------------------- */}

      <div className={classes.rightSection}>
        <Paper>
          <div className={classes.innerRightSec}>
            <Typography style={{ paddingTop: "15px" }}>
              {selectedList}
            </Typography>
            <div className={classes.grow}></div>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
            <div className={classes.outlineBorder}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  classes={{
                    formControl: classes.labelFormControl,
                    shrink: classes.shirnk,
                  }}
                >
                  A to Z
                </InputLabel>
                <Select
                  native
                  classes={{
                    root: classes.lablePadding,
                    outlined: classes.outline,
                  }}
                  value={age}
                  onChange={handleChange}
                  inputProps={{
                    name: "age",
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value={10}>Ten</option>
                  <option value={20}>Twenty</option>
                  <option value={30}>Thirty</option>
                </Select>
              </FormControl>
            </div>
          </div>
          <div style={{ padding: "0 15px" }}>
            <Divider></Divider>
          </div>

          <div style={{ display: "flex", padding: "10px 15px" }}>
            <div className={classes.search2}>
              <InputBase
                fullWidth
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                onChange={handleChange}
                name="DataType"
              />
            </div>
            <div className={classes.grow}></div>
            <Button
              classes={{ label: classes.btnLable }}
              onClick={handleAddOpen}
              variant="contained"
            >
              Add
            </Button>
          </div>
          <Dialog
            open={addOpen}
            onClose={handleAddClose}
            aria-labelledby="form-dialog-title"
            className={classes.addDialog}
          >
            <DialogTitle id="form-dialog-title">Add Credentials</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                name="name"
                label="Enter Name"
                onChange={AddNewName}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleAddClose}
                color="primary"
                variant="contained"
              >
                Cancel
              </Button>
              <Button onClick={onAdd} color="primary" variant="contained">
                Add Name
              </Button>
            </DialogActions>
          </Dialog>

          {/* -------------------------------------------- Table ------------------------------------------------------- */}
          <div style={{ width: "98%", padding: "0 15px" }}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>S.no</TableCell>
                    <TableCell>name</TableCell>

                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {names.filter(function (nam) {
                     return nam.selectedList === selectedList
                  }).map((tabitem, index) => (
                    <TableRow
                      key={tabitem.id}
                      selected={tableIndex === index}
                      hover
                      onClick={(event) => handleRowSelect(event, index)}
                      classes={{ selected: classes.selectedRow }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{tabitem.name}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => handleEditOpen(tabitem, index)}
                        >
                          <EditIcon></EditIcon>
                        </IconButton>
                        <Switch></Switch>
                        <IconButton
                          key={tabitem.id}
                          onClick={() => deleteOpen(tabitem, index)}
                        >
                          <HighlightOffIcon></HighlightOffIcon>
                        </IconButton>
                      </TableCell>

                      {/* --------------------------------- delete -----------------------------------------------------------*/}
                      <Dialog
                        open={isDelete.isOpen}
                        onclose={() => deleteClose(tabitem)}
                        aria-labelledby="form-dialog-title"
                        className={classes.addDialog}
                      >
                        <DialogTitle id="form-dialog-title">Delete</DialogTitle>
                        <DialogContent>
                          Are you sure you want to delete the item
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={deleteCell}
                            color="primary"
                            variant="contained"
                          >
                            Yes
                          </Button>
                          <Button
                            onClick={() => deleteClose(tabitem)}
                            color="primary"
                            variant="contained"
                          >
                            No
                          </Button>
                        </DialogActions>
                      </Dialog>

                      {/* ---------------------------------edit -----------------------------------------------------------*/}
                      <Dialog
                        open={editOpen.isOpen}
                        onClose={() => handleEditClose(tabitem)}
                        aria-labelledby="form-dialog-title"
                        className={classes.addDialog}
                      >
                        <DialogTitle id="form-dialog-title">
                          Edit Credentials
                        </DialogTitle>
                        <DialogContent>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            name="name"
                            label={editOpen.id.name}
                            onChange={onEditChange}
                            fullWidth
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={() => handleEditClose(tabitem)}
                            color="primary"
                            variant="contained"
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={onEdit}
                            color="primary"
                            variant="contained"
                          >
                            Add Name
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Paper>
      </div>
    </div>
  );
}

export default withAllContexts(Masters);
