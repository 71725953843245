import React from "react";
import { withRouter } from "react-router-dom";
import { Cookies } from 'react-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
//import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
//import Cookies from 'universal-cookie';

import {
  withStyles,
  Grid,
  TextField,
  Typography,
  Card,
  Switch,
  // CardHeader,
  Avatar,
  Button,
  IconButton,
  Divider,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Input,
  Chip,
  Radio,
  RadioGroup,
  FormControlLabel,
  // AppBar,
  // Toolbar,
} from "@material-ui/core";
// import Select from '@material-ui/core/Select';
import AddIcon from "@material-ui/icons/Add";
import Masters from "../masterr/masterr";
import Dialog from "../dialog/index";
import axios from "axios";
import Alert from "../alert";
// import DeleteIcon from "@material-ui/icons/Delete";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { v4 as uuidv4, v4 } from "uuid";
// import { GraphQLNonNull } from "graphql";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// import { keys } from "@material-ui/core/styles/createBreakpoints";
import { withAllContexts } from "../../hocs";
import { SubHeader } from "../subNav";
import { SearchComponent } from "../search";
import TabComponent from "../tabs";
import { DrawerProps } from "../../utils";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Privileges from "../privileges/privileges";
import { KeyboardBackspace, Timer10 } from "@material-ui/icons";
import config from '../../config';
import DeleteIcon from '@material-ui/icons/Delete';
import { Tooltip } from '@material-ui/core';
import SelectBox from '../../components/select';
// style
const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1)
  },
  root: {
    background: "#f4f4f5",
    // padding:theme.spacing(2)
    // height: "90vh",

  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  divider: {
    height: 40,
    alignSelf: "center",
    margin: "0px 15px",
    backgroundColor: "#eee",
  },
  paper: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #0000001A",
    borderRadius: "10px",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0 0 11px rgba(33,33,33,.2)",
      transition: "0.5s",
    },
  },
  paperproject: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #0000001A",
    borderRadius: "10px",
    paddingTop: theme.spacing(2),
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
    paddingBottom: 5,
    marginBottom: theme.spacing(2),
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0 0 11px rgba(33,33,33,.2)",
      transition: "0.5s",
    },
  },
  containerStyle: {
    padding: theme.spacing(2),
    paddingTop: 0,
    overflowX: "hidden",
  },

  addbtn: {
    color: "#fff",
    background: "#131947",
    borderRadius: 10,
    cursor: "pointer",

    fontSize: 14,
    alignItems: "center",
    padding: "6px 13px 8px 6px",
    display: "flex",
    width: "fit-content",
    float: "right",
    margin: "24px 20px 6px",

    [theme.breakpoints.only("xs")]: {
      margin: "24px 20px 6px",
    },
  },
  dialogbox: {
    padding: 24,
  },
  dialogBox: {
    padding: "24px 24px 0 24px",
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    padding: "14px 25px",
    borderBottom: "2px solid #C7CCCC",
  },
  savebtns: {
    color: "#fff",
    background: "#131947",
    borderRadius: 10,
    cursor: "pointer",
    fontSize: 14,
    alignItems: "center",
    padding: "10px 14px",
    width: "83%",
    margin: "0 20px",
    textAlign: "center",
  },
  card: {
    margin: "12px 20px",
    borderRadius: 8,
    boxShadow: "0px 5px 23px #00000014",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    "& .MuiCardHeader-title": {
      fontSize: 15,
      fontWeight: 500,
    },
  },
  choosebtn: {
    background: "#f4f4f5",
    borderRadius: 6,
    padding: "3px 3px",
    margin: "5px 0px",
    textTransform: "capitalize",
    fontSize: 13,
    letterSpacing: 1,
    opacity: 0.8,
    "& a": {
      textDecoration: "unset",
      color: "#222222bf",
    },
  },
  tabTitle: {
    flexGrow: 1,
    textAlign: "center",
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    fontSize: 17,
    letterSpacing: 1,
    [theme.breakpoints.only("xs")]: {
      textAlign: "left",
      fontSize: 16,
      display: "none",
    },
  },
  appbar: {
    background: theme.palette.background.paper,
    boxShadow: "none",
    width: "100%",
    color: "rgba(0, 0, 0, 0.87)",
    borderBottom: "1px solid rgb(0 0 0 / 11%)",
  },
  menulist: {
    color: "rgb(177 176 176)",
    margin: "0px 12px",
    fontWeight: 400,
    padding: 20,
    cursor: "pointer",
    fontSize: 15,
    letterSpacing: 1,
  },
  active: {
    borderBottom: "2px solid #131947",
  },
  backBtn: {
    background: "gray",
    "&:hover": {
      background: "gray",
    },
    color: "white",
    textTransform: "capitalize",
    borderRadius: "8px",
  },
  store: {
    marginTop: -7,
    paddingLeft: 2
  },
  btnContain: {
    display: "flex",
    justifyContent: "flex-end",
  },
  backDiv: {
    margin: "23px 30px 10px 0px",
  },
  buttonView: {
    boxShadow: "0px 5px 23px #00000014",
    fontSize: 13,
    textTransform: "capitalize",
    letterSpacing: 1,
    "& .MuiTypography-body1": {
      fontWeight: 500,
    },
  },
  hoverEffect: {
    "&:hover": {
      transform: "scale(1.1)",
      transition: "transform 0.8s",
    },
  },
  formControl: {
    marginTop: "12px",
    minWidth: 120,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
});

var RefineProjectID;
// end
var ToolUrl = {
  // strategic_modeler: {
  //   url:"http://167.71.228.116:9015/",
  //   is_active:true,
  //   icon:''
  // }
  // ,
  // enterprise_modeler:{
  //   url: "http://167.71.228.116:9011/",
  //   is_active:true,
  //   icon:''
  // } ,
  // qps: {
  //   url:"http://167.71.228.116:9010/",
  //   is_active:true,
  //   icon:''
  // }
  // ,
  // docEntity:{
  //   url:"http://167.71.228.116:9000/",
  //   is_active:true,
  //   icon:''
  // } ,
  // ui_designer: {s
  //   url:"http://167.71.228.116:9001/uibuilder",
  //   is_active:true,
  //   icon:''
  // },



  'Ainqa-Refine': { url: `${config.AinqaRefine}/`, is_active: true, icon: '01_strategic_modeler' },
  'Schedule & Monitor': { url: `${config.Scheduler}/`, is_active: true, icon: '11_rule_builder' },
  'Data Pipeline': { url: `${config.ETL}/`, is_active: true, icon: '04_entity_designer_d360' },
  'Data Hub': { url: `${config.Datalake}/`, is_active: true, icon: '08_ui_reports_builder' },
  'Strategic Modeler': { url: `${config.Strategic_Modeler}/`, is_active: true, icon: '01_strategic_modeler' },
  'Enterprise Modeler': { url: `${config.Enterprise_Modeler}/`, is_active: true, icon: '02_enterprise_modeler' },
  'QPAS Builder': { url: `${config.QPAS_Builder}/`, is_active: true, icon: '03_qpas_builder' },
  'Entity Designer (D360)': { url: `${config.Entity_Designer}/`, is_active: true, icon: '04_entity_designer_d360' },
  // 'Visualisation': { url: `${config.MLOPS}/`, is_active: true, icon: '13_job_scheduler' },
  'Visualisation': { url: `${config.MLOPS}/`, is_active: true, icon: '13_job_scheduler' },
  // 'Note Book': { url: `${config.NoteBook}/`, is_active: false, icon: '10_ui_flow_builder' },
  // 'Code Hub': { url: `${config.GitSupportTool}/`, is_active: false, icon: '03_qpas_builder' },
  // 'MLOPS': { url: `${config.MLOPS}/`, is_active: false, icon: '13_job_scheduler' },

  // 'UI Reports Builder': { url: `${config.UI_Reports_Builder}/uibuilder?projectName=Mak-Test&metaDataId=6a615ba3-7339-476d-8401-3b61d2cafddf&&UidesignerModelerid=decdff1e-267d-4ecc-a80c-1fce619384bd`, is_active: true, icon: '08_ui_reports_builder' },
  // 'UI Dashboard Builder': { url: `${config.UI_Dashboard_Builder}/uibuilder?projectName=Mak-Test&metaDataId=6a615ba3-7339-476d-8401-3b61d2cafddf&&UidesignerModelerid=decdff1e-267d-4ecc-a80c-1fce619384bd`, is_active: true, icon: '09_ui_dashboard_builder' },
  // 'UI Flow Builder': { url: `${config.UI_Flow_Builder}/uiflow`, is_active: true, icon: '10_ui_flow_builder' },
  // 'Rule Builder (GRC++)': { url: `${config.Rule_Builder}/`, is_active: true, icon: '11_rule_builder' },
  // 'Service & Service Flow Builder': { url: `${config.Service_Service_Flow_Builder}/file/d/1E5EtdzSeiM7iOCWj5almf41gzGwJId9s/view?usp=sharing`, is_active: true, icon: '12_service_and_service_flow_builder' },
  // 'Job Scheduler': { url: `${config.Job_Scheduler}`, is_active: false, icon: '13_job_scheduler' },
  // 'Job Monitor': { url: `${config.Job_Monitor}`, is_active: false, icon: '14_job_monitor' },
  // 'Task & Document Worfklow Builder': { url: `${config.Task_Document_Worfklow_Builder}`, is_active: false, icon: '15_tasks_and_documents_workflow_builder' },
  // 'Chatbot Workflow Builder': { url: `${config.Chatbot_Workflow_Builder}`, is_active: true, icon: '16_chatbot_workflow_builder' },
  // 'Integrated Test Engine': { url: `${config.Integrated_Test_Engine}/projects?sort=-analysis_date`, is_active: true, icon: '17_integrated_test_engine' },
  // 'Alerts Hub': { url: `${config.Alerts_Hub}/`, is_active: true, icon: '18_alerts_hub' },
  // 'AVC - Video Conferencing': { url: `${config.AVC_Video_Conferencing}`, is_active: true, icon: '19_avc_video_conferencing' },
  // 'Runtime Admin Console': { url: `${config.Runtime_Admin_Console}/_db/_system/_admin/aardvark/index.html#dashboard`, is_active: true, icon: '20_runtime_admin_console' },
  // 'ELK Dashboard': { url: `${config.ELK_Dashboard}/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-15M,to:now))&_a=(columns:!(_source),filters:!(),index:%27588aaed0-662c-11eb-8e66-afd311f6d58d%27,interval:auto,query:(language:kuery,query:%27%27),sort:!())`, is_active: true, icon: '21_elk_dashboard' },
  // 'Promethus Dashboard': { url: `${config.Promethus_Dashboard}/graph`, is_active: true, icon: '22_promethus_dashboard' },
  // 'NiFi': { url: `${config.NiFi}`, is_active: true, icon: '23_nifi' },
  // "Message Catalog": {
  //   url: `${config.Message_Catalog}/messageCatalog`,
  //   is_active: true,
  //   icon: "23_nifi",
  // },
};





var darkColorCli = [
  "#7B241C",
  "#76448A",
  "#1F618D ",
  "#117864",
  "#B9770E ",
  "#1B2631 ",
  "#7B241C",
  "#76448A",
  "#1F618D ",
  "#117864",
  "#B9770E ",
  "#1B2631 ",
];
var lightColorCli = [
  "#FADBD8",
  "#D2B4DE ",
  "#F5EEF8",
  "#E8F8F5",
  "#FDEBD0",
  "#F2F4F4 ",
  "#FADBD8",
  "#D2B4DE ",
  "#F5EEF8",
  "#E8F8F5",
  "#FDEBD0",
  "#F2F4F4 ",
];





const languagesAll = [
  { language: 'Amharic', code: 'am' },
  { language: 'Arabic', code: 'ar' },
  { language: 'Bangla', code: 'bn' },
  { language: 'Belarusian', code: 'be' },
  { language: 'Bulgarian', code: 'bg' },
  { language: 'Chinese (Hong Kong)', code: 'yue-hant' },
  { language: 'Chinese (Simplified)', code: 'zh' },
  { language: 'Chinese (Traditional)', code: 'zh-hant' },
  { language: 'English', code: '' },
  { language: 'French', code: 'fr' },
  { language: 'German', code: 'de' },
  { language: 'Greek', code: 'el' },
  { language: 'Gujarati', code: 'gu' },
  { language: 'Hebrew', code: 'he' },
  { language: 'Hindi', code: 'hi' },
  { language: 'Italian', code: 'it' },
  { language: 'Japanese', code: 'ja' },
  { language: 'Kannada', code: 'kn' },
  { language: 'Malayalam', code: 'ml' },
  { language: 'Marathi', code: 'mr' },
  { language: 'Nepali', code: 'ne' },
  { language: 'Odia', code: 'or' },
  { language: 'Persian', code: 'fa' },
  { language: 'Portuguese (Brazil)', code: 'pt' },
  { language: 'Punjabi', code: 'pa' },
  { language: 'Russian', code: 'ru' },
  { language: 'Sanskrit', code: 'sa' },
  { language: 'Serbian', code: 'sr' },
  { language: 'Sinhala', code: 'si' },
  { language: 'Spanish', code: 'es' },
  { language: 'Tamil', code: 'ta' },
  { language: 'Telugu', code: 'te' },
  { language: 'Tigrinya', code: 'ti' },
  { language: 'Ukrainian', code: 'uk' },
  { language: 'Urdu', code: 'ur' },
  { language: 'Vietnamese', code: 'vi' },
];

// function getStyles(name, personName, theme) {
//   return {
//     fontWeight:
//       personName.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

class EntityComponent extends React.Component {
  // state
  constructor(props) {
    super(props);
    this.state = {
      addproject: false,
      addprojectvalue: "",
      adddescription: "",
      addduploadurl: "",
      addstoragetype: "Arango",
      addclientvalue: "",
      data: [],
      isopen: false,
      snackmsg: "",
      snacktype: "",
      tab: 0,
      tabIndex: 0,
      qpasData: [],
      addclient: false,
      clientList: [],
      status: "client",
      projectTools: [],
      projectToolLists: [],
      headerName: "QDM",
      projectId: "",
      metaDataId: "",
      databaseName: "",
      metaDataName: "",
      userdetails: {},
      language: [],
      clientDrawer: false,
      userDrawer: false,
      clientValue: '',
      users: [],
      username: '',
      email: '',
      designation: '',
      userError: {
        username: false,
        email: false
      },
      deleteDrawer: false,
      deleteClient: false,
      deleteClientId: '',
      deleteProject: false,
      deleteProjectId: false,
      themeColors: [
        {
          label: 'Primary',
          value: "primary"
        },
        {
          label: 'Secondary',
          value: "secondary"
        }
      ],
      clientValidate: false,
      isShowVisualisation: false,
      searchClientList: [],
      searchProjectList: []

    };
  }
  // end
  componentDidMount() {
    this.getClient();
    localStorage.setItem("header_name", "QDM");
    console.log(config, 'config file')
    this.getUserList();
  }
  async getProject() {


    let payload = {
      "db_name": "QDM_DPM",
      "entity": "client_vs_db_stg",
      "filter": `client_vs_db_stg.client=='${localStorage.getItem("client_name")}'&& client_vs_db_stg.metadataname!=null`,
      "return_fields": "client_vs_db_stg"
    }

    await axios
      .post(`${config.entity_api_url}/api/read_documents`, payload).then(async (response) => {

        this.setState({ data: response.data.result });
        this.setState({ searchProjectList: response.data.result })
      })
      .catch(err => {
        debugger;
      });
  };
  //   await axios
  //     .post(`${config.entity_api_url}/api/list_schema`, {
  //       filter: {
  //         columname: "client",
  //         operator: "equals",
  //         value: localStorage.getItem("client_name"),
  //       },
  //     })
  //     .then((response) => {
  //       console.log(response);
  //       // let  state = this.state;
  //       this.setState({ data: response.data.Result });
  //     })
  //     .catch((err) => {});
  // }


  addProject = () => {
    //   add project modal open
    const state = this.state;
    this.setState({
      addproject: !state.addproject,
    });
  };
  onTabClick = (tab) => {
    this.setState({
      ...this.state,
      tabIndex: tab,
    });
  };

  switchFunction = () => {
    const { tabIndex } = this.state;
    if (tabIndex === 0) {
      this.addClient();
      let state = this.state;
      this.setState({
        ...state,
        clientDrawer: true
      })
    } else if (tabIndex === 1) {
      let state = this.state;
      this.setState({
        ...state,
        userDrawer: true
      })
      this.addUser();
    } else if (tabIndex === 2) {
      this.addRoles();
    }
  };



  giveMeButtonName = () => {
    const { tabIndex } = this.state;
    if (tabIndex === 0) {
      return "Add Client";
    } else if (tabIndex === 1) {
      return "Add Users";
    } else if (tabIndex === 2) {
      return "Add Roles";
    } else if (tabIndex === 3) {
      return "Masters";
    }
  };
  handleClientDrawer = () => {
    let state = this.state;

    this.setState({
      ...state,
      clientDrawer: false
    }, () => {
      this.addClient();
    })

  }

  addClient = () => {
    this.setState({ addclientvalue: "" })
    const { drawer, classes } = this.props;
    drawer.setDrawer({
      ...drawer,
      open: this.state.clientDrawer,

      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <div>
          <Typography className={classes.title}>Add Client</Typography>
          <div className={classes.dialogbox}>
            <TextField
              size="small"
              variant="outlined"
              placeholder="Client name"
              label="Client name"
              name="t1"
              inputProps={{
                maxlength: 20
              }}
              onChange={(e) =>

                this.handleCangeClient("addclientvalue", e.target.value)
              }
              fullWidth={true}
            />


          </div>
          {this.state.clientValidate === true ? <span>Please enter client name.</span> :''}
          <div
            className={classes.savebtns}
            onClick={() => this.onSubmitClient()}
          >
            {" "}
            Submit{" "}
          </div>
        </div>
      ),
    });
  };
  handleDeleteDrawerClose = () => {
    this.setState(prevState => ({
      ...prevState,
      deleteDrawer: false
    }))
  }

  handleDeleteClientDrawerClose = () => {
    // this.setState(prevState => ({
    //   ...prevState,
    //   deleteClient: false
    // }))
    this.setState({ deleteClient: false })
  }

  handleDeleteProjectDrawerClose = () => {
    this.setState(prevState => ({
      ...prevState,
      deleteProject: false
    }))
  }




  actionDeleteDrawer = (userId) => {
    this.setState(prevState => ({
      ...prevState,
      deleteDrawer: true,
      deleteUserId: userId
    }))
  }




  deleteUser = async () => {
    let { users, deleteUserId } = this.state;
    users = users.filter(_ => _.id !== deleteUserId);
    let dataList = {
      db_name: config.database,
      entity: config.userEntity,
      is_metadata: false,
      projectname: config.userEntity,
      doc: {
        // metadataId: '',
        // userId:uuidv4(),
        // projectname: config.userEntity,
        attributes: users,
      },
    };

    let data = JSON.stringify([dataList]);
    var config1 = {
      method: "post",
      url: `${config.entity_api_url}/api/upsert_document`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config1)
      .then((response) => {

        this.getUserList();
        // this.setState(prevState=>({
        //   ...prevState,
        //   isopen: true,
        //   snackmsg: "User delete successfully",
        //   snacktype: "success",
        // }));
        // this.closeUserDrawer();
        // this.handleDeleteDrawerClose()
      })
      .catch((err) => {

      });
  }

  getUserList = async () => {
    let state = this.state;
    if (state.deleteDrawer) {
      state.deleteDrawer = false;
    }
    await axios
      .post(`${config.entity_api_url}/api/read_documents`, {
        db_name: 'QDM_DPM',
        entity: 'users',
        return_fields: 'users',
      })
      .then((response) => {
        if (response?.data?.result?.[response.data.result.length - 1]?.attributes
        ) {
          let usersList = response?.data?.result?.[response.data.result.length - 1].attributes ?? [];
          this.setState({
            ...state,
            users: usersList
          })
        }
        debugger;

      })
      .catch((err) => {
        debugger;
      });
  };
  onSubmitUser = async () => {
    let state = this.state;
    let validation = false;
    let validField = {
      username: state.username,
      email: state.email,
    }
    Object.keys(validField).map((valid_f) => {
      if (state[valid_f].length <= 0) {
        validation = true;
        state.userError[valid_f] = true;
      } else {
        state.userError[valid_f] = false;
      }
    })
    if (validation) {
      this.setState({
        ...state
      }, () => {
        this.addUser();
      });
      return;
    }
    let fieldValue = {
      id: uuidv4(),
      username: state.username,
      email: state.email,
      designation: state.designation
    }
    state.users.push(fieldValue);
    let dataList = {
      db_name: config.database,
      entity: config.userEntity,
      is_metadata: false,
      projectname: config.userEntity,
      doc: {
        // metadataId: '',
        // userId:uuidv4(),
        // projectname: config.userEntity,
        attributes: state.users,
      },
    };

    let data = JSON.stringify([dataList]);
    var config1 = {
      method: "post",
      url: `${config.entity_api_url}/api/upsert_document`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios;
    axios(config1)
      .then((response) => {

        this.getUserList();
        this.setState({
          ...state,
          isopen: true,
          snackmsg: "User created successfully",
          snacktype: "success",
        });
        this.closeUserDrawer();
      })
      .catch((err) => {
      });
  }
  closeUserDrawer = () => {
    let state = this.state;
    this.setState({
      ...state,
      userDrawer: false
    }, () => {
      this.addUser();
    })
  }
  addUser = () => {
    const { drawer, classes } = this.props;
    drawer.setDrawer({
      ...drawer,
      open: this.state.userDrawer,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <div>
          <Typography className={classes.title}>Add User</Typography>
          <div className={classes.dialogBox}>
            <TextField
              size="small"
              variant="outlined"
              placeholder="User"
              label="User"
              name="User"
              error={this.state.userError.username ? true : false}
              helperText={this.state.userError.username ? 'Please  enter the username' : ''}
              onChange={(e) => this.handleCange("username", e.target.value)}
              fullWidth={true}

            />
          </div>
          <div className={classes.dialogBox}>
            <TextField
              size="small"
              variant="outlined"
              placeholder="E-Mail"
              label="E-Mail"
              name="E-Mail"
              error={this.state.userError.email ? true : false}
              helperText={this.state.userError.email ? 'Please  enter the email' : ''}
              onChange={(e) => this.handleCange("email", e.target.value)}
              fullWidth={true}

            />
          </div>
          <div className={classes.dialogbox}>
            <TextField
              size="small"
              variant="outlined"
              placeholder="Desigination"
              label="Desigination"
              name="Desigination"
              onChange={(e) =>
                this.handleCange("designation", e.target.value)
              }
              fullWidth={true}
            />
          </div>
          <div
            className={classes.savebtns}
            onClick={() => this.onSubmitUser()}
          >
            {" "}
            Add User{" "}
          </div>
        </div>
      ),
    });
  };

  addRoles = () => {
    const { drawer, classes } = this.props;
    drawer.setDrawer({
      ...drawer,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <div>
          <Typography className={classes.title}>Add Role</Typography>
          <div className={classes.dialogBox}>
            <TextField
              size="small"
              variant="outlined"
              placeholder="Role Name"
              label="Role Name"
              onChange={(e) => this.handleCange("addRoleName", e.target.value)}
              fullWidth={true}
            />
          </div>
          <div className={classes.dialogbox}>
            <TextField
              size="small"
              variant="outlined"
              placeholder="Role Description"
              label="Role Description"
              onChange={(e) =>
                this.handleCange("addRoleDescription", e.target.value)
              }
              fullWidth={true}
            />
          </div>
          <div
            className={classes.savebtns}
          // onClick={() => this.onSubmitClient()}
          >
            {" "}
            Add Roles{" "}
          </div>
        </div>
      ),
    });
  };

  onSubmitClient = async () => {
    // clientList.push({
    //   name:this.state.addclientvalue
    // });
    console.log(config);

    const found = this.state.clientList.some(el => el.clientname === this.state.addclientvalue);
    var re = /^[a-z\d\_\s]+$/i;
    this.state.addclientvalue = this.state.addclientvalue.trim();
    var re1 = /^\d+$/;
    if ((this.state.addclientvalue == "") || (this.state.addclientvalue.trim() == "")) {
      toast("Please enter client name.");
    }
    else if (found) {
      toast("Client name already exists");

    }
    else if (!re.test(this.state.addclientvalue.trim())) {
      toast("Client name can contain only alphabets,numbers and underscore");
    }
    else if ((this.state.addclientvalue[0] === '0') || (this.state.addclientvalue[0] === '1') || (this.state.addclientvalue[0] === '2') || (this.state.addclientvalue[0] === '3') || (this.state.addclientvalue[0] === '4') || (this.state.addclientvalue[0] === '5') || (this.state.addclientvalue[0] === '6') || (this.state.addclientvalue[0] === '7') || (this.state.addclientvalue[0] === '8') || (this.state.addclientvalue[0] === '9')) {
      toast("Client name cannot start with a number");
    }
    else {


      let payload = {
        client: this.state.addclientvalue.trim(),
        db_name: this.state.addclientvalue.trim(),
        metadataId: "",
        metadataname: "",
        attributes: [],
        metadata: [],
        entity_relationship: [],

      };
      this.setState({ clientValidate: false })
      let dataList = {
        db_name: config.database,
        entity: config.clientEntity,
        is_metadata: false,

        doc: {
          projectname: config.clientEntity,
          client: this.state.addclientvalue.trim(),
          projectId: uuidv4(),
          clientId: uuidv4(),
          active: 'Y'
          // metadataId: '',
          // userId:uuidv4(),
          // projectname: config.userEntity,
        },
      };
      let data = JSON.stringify([dataList]);
      var config1 = {
        method: "post",
        url: `${config.entity_api_url}/api/upsert_document`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      await axios;
      axios(config1)
        .then((response) => {
          let state = this.state;
          this.createClientDirectory(this.state.addclientvalue.trim());
          this.setState({
            addclientvalue: "",
            // isopen: true,
            // snackmsg: "client saved successfully",
            // snacktype: "success",
          });
          debugger;
          this.getClient();
          this.handleClientDrawer();
          window.location.reload();
        })
        .catch((err) => {
          this.setState({
            isopen: true,
            snackmsg: "client already exists ",
            snacktype: "error",
          });
          this.setState({
            addclient: false,
          });
        });
      toast("Client Added Succesfully");


    }

  };


  createClientDirectory = async (clientname) => {
    await axios.request({
      url: 'http://datahub.ainqaplatform.in/webhdfs/v1/' + clientname + '?op=MKDIRS',
      method: 'put',
      headers: { "Access-Control-Allow-Origin": "*" }


    })
      .then(res => {

        console.log(res);

        // this.setState({ statusReject: false, statusApprove: true, statusApprovalPending: false, isLoading: false });
      })
      .catch(err => {
        console.log(err);
      });


  }


  getClient = async () => {
    // let clientList = this.state.clientList;
    // let payload = {
    //     "db_name": "adata",
    //     "entity": "client_vs_db_stg",
    //     "return_fields": "client_vs_db_stg"

    // }
    // await axios
    //         .post('http://13.71.3.63:8084/api/read_documents', payload).then(async (res) => {
    //             // let value = localStorage.metaId;
    //             // let value = res.data.clientdb.Result[0].properties.doc.metadataId;
    //            let state = this.state;
    //            state.clientList = res.data;
    //           this.setState({
    //             ...state
    //           })
    //           if(localStorage.getItem('client_id')){
    //             this.getProject();
    //           }
    //           // this.getClient();
    //         })
    //         .catch(err => {
    //             this.setState({ isopen: true,snackmsg:"Network error ",snacktype:"error" })

    //         });
    let payload = {
      db_name: config.database,
      query: `
      FOR doc IN client_vs_db_stg
FILTER  doc.active=='Y'
COLLECT db_name=doc.db_name,clientname=doc.client,clientId=doc.clientId INTO clientgroup
Let ProjectCount=Count(For u in client_vs_db_stg Filter clientname==u.client && u.metadataname!=null Return u)
RETURN DISTINCT { clientname: clientname,clientId:clientId,projcount:ProjectCount}`
      //      FOR doc IN client_vs_db_stg
      // FILTER doc.active=='Y'
      // COLLECT db_name=doc.db_name,clientname=doc.client,clientId=doc.clientId, projcount=length(doc.client) INTO clientgroup
      // RETURN DISTINCT { clientname: clientname,clientId:clientId,projcount:projcount}`
      // query:
      //   "FOR doc IN client_vs_db_stg FILTER doc.active=='Y' COLLECT clientname=doc.client, dbname=doc.db_name INTO clientgroup RETURN {clientname:clientname,clientdetails:clientgroup[*].doc,projectcount:LENGTH(clientgroup)}",
    };
    await axios
      .post(`${config.entity_api_url}/api/execute_aql`, payload)
      .then(async (res) => {
        // let value = localStorage.metaId;
        // let value = res.data.clientdb.Result[0].properties.doc.metadataId;
        let state = this.state;
        state.clientList = res.data;
        state.searchClientList = res.data;
        this.setState({
          ...state,
        });
        // if(localStorage.getItem('client_id')){
        //   this.getProject();
        // }
        // this.getClient();
      })
      .catch((err) => {
        this.setState({
          isopen: true,
          snackmsg: "Network error ",
          snacktype: "error",
        });
      });
  };
  navProject = (id, name, db) => {
    localStorage.setItem("client_id", id);
    localStorage.setItem("client_name", name);
    localStorage.setItem("client_db", db);
    this.setState({
      status: "project",
      headerName: name,
    });
    this.props.handleHeaderName(name);

    // localStorage.setItem('header_name',name)

    this.getProject();
  };
  backToClient = () => {
    localStorage.clear();
    this.setState({
      status: "client",
      headerName: "QDM",
    });
    this.getClient();
    this.props.handleHeaderName("QDM");

    // localStorage.setItem("header_name", "QDM");
  };

  createDatabase = async (Projectname) => {



    let dataList = {
      db_name: Projectname,

    };
    let data = JSON.stringify(dataList);
    var config1 = {
      method: "post",
      url: `${config.entity_api_url}/api/add_database`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      data: data,
    };

    await axios;
    axios(config1)
      .then((response) => {

        console.log(response);

        var entity = ["Data_source_mapping", "Export_log", "QDMObject", "QDMobjectconfig", "QDMQueries",
          "QDMSequence", "FlowFile", "FlowSchedulers"];

        var type = "document";

        this.createDatabaseCollection(Projectname, entity, type);

      })
      .catch((err) => {
        console.log(err);

      });


  };




  UpsertdocumentCollection = async (DBName, entity, doc) => {

    let projectdataList = {
      db_name: DBName,
      entity: entity,
      is_metadata: false,
      doc: doc,

    };

    let data = JSON.stringify([projectdataList]);
    var config1 = {
      method: "post",
      url: `${config.entity_api_url}/api/upsert_document`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios;
    axios(config1)
      .then((response) => {

        debugger;
      })
      .catch((err) => {
      });

  };

  createDatabaseCollection = async (DBname, entity, type) => {

    let dataList = {
      db_name: DBname,
      entity: entity,
      type: type

    };
    let data = JSON.stringify(dataList);
    var config1 = {
      method: "post",
      url: `${config.entity_api_url}/api/add_collection`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      data: data,
    };

    await axios;
    axios(config1)
      .then((response) => {


        let QDMConfigdata = {
          objectid: "12",
          objectidgeneration: "uuid",
          storagetype: "AWS",
          name: "qdm",
          directoryname: "ArangoDB",
          path: "/s3",
          authtoken: null,
          os: "windows",
          isauthrequired: false,
          access_key_id: "AKIAU7X3XNJEPCC3KAGV",
          secret_access_key: "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"

        }

        if (this.state.addstoragetype !== "Arango") {
          var connectionParams = {

            uploadUrl: this.state.addduploadurl
          }

        }
        else {
          var connectionParams = {

            ArangoUrl: this.state.addduploadurl
          }
        }






        let ProjectConfigdata =
        {
          projectName: this.state.addprojectvalue,
          clientName: localStorage.getItem("client_name"),
          dataStorage: DBname,
          connectionParams: connectionParams,
          type: this.state.addstoragetype
        }




        this.UpsertdocumentCollection(DBname, "QDMobjectconfig", QDMConfigdata);
        this.UpsertdocumentCollection("openrefine", "Project_Configuration", ProjectConfigdata);
        console.log(response);

      })
      .catch((err) => {
        console.log(err);

      });

  }

  createProjectAzkaban = async (name, description) => {

    let dataList = {
      name: name,
      description: description
    };
    let data = JSON.stringify(dataList);
    var config1 = {
      method: "post",
      url: `${config.entity_api_url}/manager?action=create`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*"
      },
      data: data,
    };

    await axios;
    axios(config1)
      .then((response) => {
        console.log(response);

      })
      .catch((err) => {
        console.log(err);

      });
  }

  createProjectDirectory = async (clientname, projectname) => {
    await axios.request({
      url: 'http://datahub.ainqaplatform.in/webhdfs/v1/' + clientname + '/' + projectname + '?op=MKDIRS',
      method: 'put',
      headers: { "Access-Control-Allow-Origin": "*" }
    })
      .then(res => {
      })
      .catch(err => {
        console.log(err);
      });
  }

  onSubmit = async (tab) => {
    //   add project name list
    debugger;
    const found = this.state.data.some(el => el.projectname === this.state.addprojectvalue);
    this.state.addprojectvalue = this.state.addprojectvalue.trim();
    var re = /^[a-z\d\_\s]+$/i;
    var re1 = /^\d+$/;
    if (this.state?.addprojectvalue?.length <= 0) {
      toast("Please enter project name.");
      return;
    }
    else if (found) {
      toast("project name already exists");
      return;

    }
    else if (!re.test(this.state?.addprojectvalue)) {
      toast("Project name should contain only alphabets,numbers,underscore");
      return;
    }
    else if ((this.state?.addprojectvalue[0] === '0') || (this.state?.addprojectvalue[0] === '1') || (this.state?.addprojectvalue[0] === '2') || (this.state?.addprojectvalue[0] === '3') || (this.state?.addprojectvalue[0] === '4') || (this.state?.addprojectvalue[0] === '5') || (this.state?.addprojectvalue[0] === '6') || (this.state?.addprojectvalue[0] === '7') || (this.state?.addprojectvalue[0] === '8') || (this.state?.addprojectvalue[0] === '9')) {
      toast("Client name cannot start with a number");
      return;
    }
    else if (this.state?.adddescription?.length <= 0) {
      toast("Please enter project description.");
      return;
    }
    else if (this.state?.addduploadurl?.length <= 0) {
      toast("Please enter upload url .");
      return;
    }
    else {

      let metaDataId;
      let projectId;
      let projectName;
      if (tab === 0) {
        let projectdataList = {
          db_name: "QDM_DPM",
          entity: config.clientEntity,
          is_metadata: false,

          doc: {
            client: localStorage.getItem("client_name"),
            db_name: localStorage.getItem("client_db"),
            projectname: this.state.addprojectvalue,
            storagetype: this.state.addstoragetype,
            description: this.state.adddescription,
            uploadurl: this.state.addduploadurl,
            metadataId: "",
            metadataname: this.state.addprojectvalue,
            attributes: [{
              theme: this.state?.theme ?? '',
              user: this.state?.selectUser ?? ''
            }],
            metadata: [],
            projectId: uuidv4(),
            entity_relationship: [],
            clientId: localStorage.getItem("client_id"),

          },

        };
        let projectdata = JSON.stringify([projectdataList]);
        var config1 = {
          method: "post",
          url: `${config.entity_api_url}/api/upsert_document`,
          headers: {
            "Content-Type": "application/json",
          },
          data: projectdata,
        };
        await axios;
        axios(config1)
          .then((res) => {

            this.createDatabase(localStorage.getItem("client_name") + "_" + this.state.addprojectvalue);
            this.createProjectAzkaban(this.state.addprojectvalue, this.state.addclientvalue);
            this.createProjectDirectory(localStorage.getItem("client_name"), this.state.addprojectvalue);
            projectId = res.data.Result[0].properties.doc.projectId;
            metaDataId = res.data.Result[0].properties.doc.metadataId;
            projectName = res.data.Result[0].properties.doc.metadataname;
            let state = this.state;
            this.setState({
              // isopen: true,
              // snackmsg: "project saved successfully",
              // snacktype: "success",
              addproject: !state.addproject,
              theme: '',
              selectUser: ''
            });

            this.getProject();
          })

          .catch((err) => {
            this.setState({
              isopen: true,
              snackmsg: "project already exists ",
              snacktype: "error",
            });
          });

      }



      // if(tab===0){
      // let payload = {
      //   client: localStorage.getItem("client_name"),
      //   db_name: localStorage.getItem("client_db"),
      //   metadataId: "",
      //   metadataname: this.state.addprojectvalue,
      //   attributes: [{
      //     theme:this.state?.theme ??  '',
      //     user:this.state?.selectUser ??''
      //   }],
      //   metadata: [],
      //   entity_relationship: [],
      //   clientId: localStorage.getItem("client_id"),
      // };
      // await axios
      //   .post(`${config.entity_api_url}/api/upsert`, payload)
      //   .then(async (res) => { 
      //     debugger;
      //       projectId = res.data.clientdb.Result[0].properties.doc.projectId;
      //       metaDataId = res.data.clientdb.Result[0].properties.doc.metadataId;
      //       projectName = res.data.clientdb.Result[0].properties.doc.metadataname;

      //     // let value = localStorage.metaId;
      //     // let value = res.data.clientdb.Result[0].properties.doc.metadataId;
      //     let state = this.state;
      //     this.setState({
      //       isopen: true,
      //       snackmsg: "project save successfully",
      //       snacktype: "success",
      //       addproject: !state.addproject,
      //       theme:'',
      //       selectUser:''
      //     });
      //     this.getProject();


      //   })
      //   .catch((err) => {
      //     this.setState({
      //       isopen: true,
      //       snackmsg: "project already exists ",
      //       snacktype: "error",
      //     });
      //   });
      // }

      let dataList = {
        db_name: "QDM_DPM",
        entity: "language",
        is_metadata: false,
        projectname: projectName,
        doc: {
          metadataId: metaDataId,
          languageid: projectId,
          projectname: projectName,
          attributes: this.state.language,
        },
      };

      let data = JSON.stringify([dataList]);
      var config1 = {
        method: "post",
        url: `${config.entity_api_url}/api/upsert_document`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      await axios;
      axios(config1)
        .then((response) => {

          debugger;
        })
        .catch((err) => {
        });
      // setData({ ...props.datajson,Data });

      this.props.lang.setLanguages((pre) => { return { ...pre, list: this.state.language } });
      this.setState({
        language: []
      })

      // }
      // else if(tab===1){

      // }

      // this.setState({
      //   ...state,
      //   addproject: !state.addproject
      // })
    };
    toast("Project added successfully");
  }
  handleCangeClient = (n, v) => {

    const state = this.state;
    if ('email' === n || n === 'username') {

      if (v.length <= 0) {
        state.userError[n] = true;
      } else {
        state.userError[n] = false;
      }
    }
    this.setState({
      ...state,
      [n]: v,
    });
  }
  handleCange = (n, v) => {
    //   add project handle change
    const state = this.state;
    if ('email' === n || n === 'username') {

      if (v.length <= 0) {
        state.userError[n] = true;
      } else {
        state.userError[n] = false;
      }
    }
    this.setState({
      ...state,
      [n]: v,
    }, () => {
      this.addUser();
    });
  };
  closeSnackBar() {
    this.setState({
      isopen: false,
    });
  }


  deleteProjectOpen(id) {
    this.setState({
      deleteProject: true,
      deleteProjectId: id
    })
  }

  deleteProject = async () => {
    // sugumar
    debugger;
    let valSchedule = JSON.stringify(
      {
        db_name: 'QDM_DPM',
        entity: 'client_vs_db_stg',
        filter: 'client_vs_db_stg' + "." + "projectname ==" + "'" + this.state.deleteProjectId + "'"
      }
    )
    var config1 = {
      method: "post",
      url: `${config.entity_api_url}/api/delete_document`,
      headers: {
        "Content-Type": "application/json",
      },
      data: valSchedule,
    };
    await axios;
    axios(config1)
      .then((response) => {

        var data = {
          "clientId": null,
          "projectId": this.state.deleteProjectId
        }
        axios.post("http://164.52.209.3:9095/api/deleteProjects", data)
          .then(res => {
            this.getProject();
            this.handleClientDrawer();
            this.setState({
              deleteProject: false
            })
            toast.success("project deleted successfully")
            debugger;
          })
          .catch((er) => {

          })



      })
      .catch((err) => {
      });

    // alert(metaId);
    window.location.reload(true)
  }

  // const handleClickOpenDeleteSchedule = (name, id) => {
  //   setDeleteName(name);
  //   setDeleteId(id);
  //   setOpenDeleteSchedule(true);
  // };

  deleteClientOpen(id) {
    this.setState({ deleteClient: true, deleteClientId: id })

  }
  deleteClient = async () => {

    let valSchedule = JSON.stringify(
      {
        db_name: 'QDM_DPM',
        entity: 'client_vs_db_stg',
        filter: 'client_vs_db_stg' + "." + "client ==" + "'" + this.state.deleteClientId + "'"
      }
    )
    var config1 = {

      method: "post",
      url: `${config.entity_api_url}/api/delete_document`,
      headers: {
        "Content-Type": "application/json",
      },
      data: valSchedule,
    };
    await axios;
    axios(config1)
      .then((response) => {


        var data = {
          "clientId": this.state.deleteClientId,
          "projectId": null,
        }
        axios.post("http://164.52.209.3:9095/api/deleteProjects", data)
          .then(res => {
            debugger;
            this.setState({ deleteClient: false })
            this.getClient();
            this.handleClientDrawer();
            toast.success("Client deleted successfully")
          })
          .catch((er) => {

          })

        // this.setState({ deleteClient: false })
        // this.getClient();
        // this.handleClientDrawer();
        // toast.success("Client deleted successfully")

        debugger;
      })
      .catch((err) => {
      });

    //alert(metaId);
    window.location.reload(true)
  }
  async qpas() {
    await axios
      .post(`${config.entity_api_url}/api/read_documents`, {
        db_name: localStorage.getItem("client_db"),
        entity: config.qpas_entity,
        return_fields: config.qpas_entity,
      })
      .then((response) => {
        console.log(response);
        // let  state = this.state;
        debugger;

        this.setState({ qpasData: response.data });
      })
      .catch((err) => {
        debugger;
      });
  }


  changeTab(tabValue) {
    this.setState({
      tab: tabValue,
    });
    if (tabValue === 1) {
      this.qpas();
    }
  }

  getToolsVisual = () => {
    debugger;
    this.setState({ isShowVisualisation: true });
  }

  getTools = async (metadata) => {
    debugger;
    if (typeof metadata === "undefined" || metadata === null) {
      return;
    }

    let state = this.state;
    state.projectId = metadata.projectId;
    state.metaDataId = metadata.metadataId;
    state.databaseName = metadata.db_name;
    state.metaDataName = metadata.metadataname;

    var Refineurl = ToolUrl["Ainqa-Refine"].url.substring(0, 40) + "?ProjectId=" + state.projectId + "&ProjectName=" + metadata.projectname + "&ClientName=" + metadata.client + "&ClientId=" + metadata.clientId + "&StorageType=" + metadata.storagetype;
    //var Schedulerurl = ToolUrl["Schedule & Monitor"].url.substring(0, 42) + "?ProjectId=" + state.projectId;
    var Schedulerurl = ToolUrl["Schedule & Monitor"].url.substring(0, 42) + "?ProjectId=" + state.projectId + "&ProjectName=" + metadata.projectname + "&ClientName=" + metadata.client + "&ClientId=" + metadata.clientId;


    ToolUrl["Ainqa-Refine"].url = Refineurl;
    ToolUrl["Schedule & Monitor"].url = Schedulerurl;

    state.projectToolLists = Object.keys(ToolUrl);
    this.setState({
      ...state,
      status: "tools",
    });
    this.props.handleHeaderName(metadata.metadataname);

    //   let payload = {
    //     "db_name":"QDM_DPM",
    //     "entity":"entity_fields_stg,QPAS,client_vs_db_stg",
    //     "filter":`entity_fields_stg.metadataId=='${metadataId}'&& QPAS.metadataId=='${metadataId}' && entity_fields_stg.active=='Y' && client_vs_db_stg.metadataId=='${metadataId}' && client_vs_db_stg.active=='Y'`,
    //     "return_fields":"Entity:docentity_fields_stg,qps:docQPAS,metadata:docclient_vs_db_stg"
    //     }
    // await axios
    //         .post('http://13.71.3.63:8084/api/read_documents', payload).then(async (res) => {
    //             // let value = localStorage.metaId;
    //             // let value = res.data.clientdb.Result[0].properties.doc.metadataId;
    //            let state = this.state;
    //            state.projectTools = res.data;
    //           state.projectToolLists= Object.keys(ToolUrl);
    //           this.setState({
    //             ...state
    //           })
    //           // if(localStorage.getItem('client_id')){
    //           //   this.getProject();
    //           // }
    //           // this.getClient();
    //         })
    //         .catch(err => {
    //             this.setState({ isopen: true,snackmsg:"Network error ",snacktype:"error" })

    //         });
    //   let payload = {
    //     "db_name": "QDM_DPM",
    //       "entity": "QPAS",
    //       "return_fields": "QPAS"
    //     }
    // await axios
    //         .post('http://13.71.3.63:8084/api/read_documents', payload).then(async (res) => {
    //             // let value = localStorage.metaId;
    //             // let value = res.data.clientdb.Result[0].properties.doc.metadataId;
    //            let state = this.state;
    //            state.projectTools = res?.data.filter(rdValue=>rdValue?.docQPAS?.metadataId===metadataId);
    //            state.headerName  = state.projectTools[0]?.docQPAS.projectname;
    //           //  state.projectTools = res.data;
    //     localStorage.setItem('header_name',state.projectTools[0]?.docQPAS.projectname)

    //           state.projectToolLists= Object.keys(ToolUrl);
    //           this.setState({
    //             ...state
    //           })
    //           // if(localStorage.getItem('client_id')){
    //           //   this.getProject();
    //           // }
    //           // this.getClient();
    //         })
    //         .catch(err => {
    //             this.setState({ isopen: true,snackmsg:"Network error ",snacktype:"error" })

    //         });
  };


  backToTools = () => {
    this.setState({ isShowVisualisation: false })
  }

  backToProject = () => {
    this.setState({
      status: "project",
      headerName: localStorage.getItem("client_name"),
    });
    this.props.handleHeaderName(localStorage.getItem("client_name"));
    // localStorage.setItem("header_name", localStorage.getItem("client_name"));
  };
  privileges = () => {
    this.setState({
      status: "privileges",
    });
  };
  handleLanguageChange = (value) => {
    this.setState({
      ...this.state,
      language: value,
    });
  };

  toolName = (name) => {
    switch (name) {
      case "docEntity":
        return "Entity Designer";
      case "qps":
        return "QPAS";
      case "ui_designer":
        return "UI Designer / Flow";
      case "enterprise_modeler":
        return "Enterprise Modeler";
      case "strategic_modeler":
        return "Strategic Modeler";
      default:
        return "";
    }
  };

  darkColor = (name) => {
    switch (name) {
      case "docEntity":
        return "#7B241C";
      case "qps":
        return "#76448A";
      case "ui_designer":
        return "#1F618D ";
      case "enterprise_modeler":
        return "#117864";
      case "strategic_modeler":
        return "#B9770E ";
      default:
        return "#1B2631 ";
    }
  };
  lightColor = (name) => {
    switch (name) {
      case "docEntity":
        return "#FADBD8";
      case "qps":
        return "#D2B4DE ";
      case "ui_designer":
        return "#F5EEF8";
      case "enterprise_modeler":
        return "#E8F8F5";
      case "strategic_modeler":
        return "#FDEBD0";
      default:
        return "#F2F4F4 ";
    }
  };
  clientColorChanges = (index) => {
    return index % 10;
  };
  render() {
    const { classes } = this.props;

    // const clientId = localStorage.getItem('client_id');

    const {
      addproject,
      data,
      isopen,
      snackmsg,
      snacktype,
      tab,
      qpasData,
      clientList,
      addclient,
      status,
      projectToolLists,
      projectTools,
      projectId,
      metaDataId,
      databaseName,
      metaDataName,
      language,
      deleteDrawer,
      themeColors,
      searchClientList,
      searchProjectList,
      deleteClient,
      deleteProject
    } = this.state;

    console.log("selected", language);
    const clientsearch = (event) => {
      var sample = [];
      sample = clientList.filter((name) =>
        name.clientname.toLowerCase().includes(event.toLowerCase())
      );
      this.setState({ searchClientList: sample })
    }
    const projectsearch = (event) => {
      console.log(data);
      console.log(event);
      var sample = [];
      sample = data.filter((name) =>
        name.projectname.toLowerCase().includes(event.toLowerCase())
      );
      this.setState({ searchProjectList: sample })
    }

    return (
      <div className={classes.root}>
        <ToastContainer autoClose={3000} />
        {isopen && (
          <Alert
            severity={snacktype}
            open={isopen}
            vertical={"top"}
            horizontal={"right"}
            msg={snackmsg}
            onclose={() => this.closeSnackBar()}
          />
        )}
        <Dialog
          open={deleteDrawer}
          handleClose={() => this.handleDeleteDrawerClose()}
          component={
            <div>
              <div style={{ padding: "10px", textAlign: "center" }}>
                <Typography className={classes.title} style={{ fontSize: '1.2rem' }}>Are you sure, you want to delete?</Typography>

              </div>
              <div style={{ textAlign: "center", marginTop: "15px", padding: "10px" }}>
                <Button variant="contained" style={{ marginRight: "10px" }} onClick={() => this.handleDeleteDrawerClose()}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={() => this.deleteUser()}>
                  Submit
                </Button>
              </div>
            </div>
          }
        />
        {status == "project" ? (
          <>
            <SubHeader
              title="Projects"
              backButtonAction={() => this.backToClient()}
              // hideSearch
              buttonName="Add Project"
              buttonAction={() => this.addProject()}
            />
            <Grid container justify="flex-end">

              <TextField type="text" label="Search" size='small' variant="outlined" style={{ width: 300, marginTop: -43, marginRight: 181 }} placeholder="Search . . ." onChange={(event) => projectsearch(event.target.value)} />
            </Grid>
            {/* dialog box */}
            <Dialog
              open={addproject}
              handleClose={() => this.addProject()}
              component={
                <div>
                  <Typography className={classes.title}>Add Project
                    <span style={{ float: "right", marginTop: -8 }}> <IconButton onClick={() => this.addProject()} ><HighlightOffIcon /></IconButton></span>
                  </Typography>
                  <div className={classes.dialogbox}>

                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        <TextField
                          variant="outlined"
                          placeholder="Project name"
                          label="Project name"
                          inputProps={{
                            maxlength: 20
                          }}
                          onChange={(e) =>
                            this.handleCange("addprojectvalue", e.target.value)
                          }
                          fullWidth={true}
                          size='small'
                        />

                      </Grid>
                      <Grid item md={12}>

                        <TextField
                          variant="outlined"
                          placeholder="Description"
                          label="Description"
                          inputProps={{
                            maxlength: 50
                          }}
                          onChange={(e) =>
                            this.handleCange("adddescription", e.target.value)
                          }
                          fullWidth={true}
                          size='small'
                        />
                      </Grid>
                      <Grid item md={12}>

                        <TextField
                          variant="outlined"
                          placeholder={this.state.addstoragetype !== "Arango" ? "UploadUrl" : "DB Url"}
                          label={this.state.addstoragetype !== "Arango" ? "UploadUrl" : "DB Url"}
                          inputProps={{
                            maxlength: 200
                          }}
                          onChange={(e) =>
                            this.handleCange("addduploadurl", e.target.value)
                          }
                          fullWidth={true}
                          size='small'
                        />
                      </Grid>


                      <Grid item md={12}>

                        <RadioGroup aria-label="db" name="db1" defaultValue={this.state.addstoragetype} onChange={(e) =>
                          this.handleCange("addstoragetype", e.target.value)
                        }>
                          <h5 className={classes.store}>Storage</h5>
                          <div style={{ display: "flex", marginTop: -16 }}>
                            <FormControlLabel value="Arango" control={<Radio />} label="Arango DB" />
                            <FormControlLabel value="Hadoop" control={<Radio />} label="Hadoop" />
                            <FormControlLabel value="S3Bucket" control={<Radio />} label="S3Bucket" />
                          </div>
                        </RadioGroup>

                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        {/* <FormControl variant="outlined" className={classes.formControl} style={{width: "100%"}}>
                          <InputLabel htmlFor="filled-age-native-simple">Users</InputLabel>
                          <Select
                            native
                            value={this.state?.selectUser}
                            onChange={(e)=> this.handleCange("selectUser", e.target.value) }
                            inputProps={{
                              name: 'selectUser',
                              id: 'filled-selectUser-native-simple',
                            }}
                            fullWidth={true}
                            label="Users"
                          >
                            <option value="" />
                            {
                              this.state?.users?.map((ur,index)=>(
                                <option value={ur.id}>{ur.username}</option>
                              ))
                            }
                          </Select>
                        </FormControl> */}
                        {/* <SelectBox
                          list={this.state?.users ?? []}
                          classname={classes.bottomSpace}
                          onchange={(e, value) => { this.handleCange("selectUser", value) }}
                          value={this.state?.selectUser}
                          Optionlabel='username'
                          title='Users'
                        // error={error.template}
                        /> */}
                      </Grid>
                      {/* <Grid item md={12}>
                        <SelectBox
                          list={languagesAll.map(_ => {
                            return {
                              ..._,
                              label: _.language,
                              value: _.code
                            }
                          }) ?? []}
                          classname={classes.bottomSpace}
                          onchange={(e, value) => { this.handleLanguageChange(value) }}
                          value={language}
                          Optionlabel='language'
                          title='Select your Language'
                        // error={error.template}
                        />
                      </Grid> */}
                    </Grid>

                    {/* <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth={true}
                    >
                      <InputLabel id="demo-mutiple-chip-label">
                        Select your Language
                      </InputLabel>
                      <Select
                       
                        labelId="demo-mutiple-chip-label"
                        id="demo-mutiple-chip"
                        multiple
                        value={language}
                        onChange={(e)=>this.handleLanguageChange(e)}
                        label="Select your Language"
                        input={<Input id="select-multiple-chip" variant="outlined"/>}
                        renderValue={(selected) => (
                          <div className={classes.chips}>
                            {selected.map((value) => (
                              <Chip
                                key={value.code}
                                label={value.language}
                                className={classes.chip}
                              ></Chip>
                            ))}
                          </div>
                        )}
                        MenuProps={this.MenuProps}
                      >
                        {languagesAll.map((lang) => (
                          <MenuItem key={lang.code} value={lang} >
                            {lang.language}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}
                  </div>
                  <div
                    className={classes.savebtns} style={{ marginTop: -23 }}
                    onClick={() => this.onSubmit(tab)}
                  >
                    {" "}
                    Submit{" "}
                  </div>
                </div>
              }
            />
            {
              <Grid container className={classes.containerStyle}>

                {searchProjectList?.map((v, index) => {
                  return (
                    <>
                      {console.log(index)}
                      {index === -1 ? (
                        ""
                      ) : (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          style={{ padding: 16 }}
                          className={classes.hoverEffect}
                        >
                          <div
                            className={`${classes.paperproject} ${classes.flexCenter}`}
                            style={{
                              minHeight: 150,
                              justifyContent: "center",
                              flexDirection: "column",
                              marginBottom: 0,
                            }}
                            onClick={() => this.getTools(v)}
                          >
                            <Avatar
                              aria-label="recipe"
                              className={classes.avatar}
                              style={{
                                marginTop: 12,
                                marginBottom: 5,
                                textTransform: "capitalize",
                                backgroundColor: `${darkColorCli[this.clientColorChanges(index)]
                                  }`,
                              }}
                            >

                              {/* {v?.metadataname.slice(0, 1)} */}
                              {v?.metadataname.slice(0, 1)}
                            </Avatar>



                            <Typography
                              variant="h6"

                              style={{ textAlign: "center", fontWeight: 400 }}
                            >
                              {v?.metadataname}
                            </Typography>

                            <Divider style={{ width: "100%", marginTop: 30 }} />
                            <Grid container

                              justify="flex-end"
                            >
                              <Button
                                variant="outlined"
                                color="primary"
                                className={classes.buttonView}
                                style={{ marginTop: 7, marginRight: 10, marginBottom: 2 }}

                                onClick={(event) => { this.deleteProjectOpen(v?.metadataname); event.stopPropagation(); }}
                              >
                                DELETE

                              </Button>
                            </Grid>

                            <Dialog
                              open={deleteProject}
                              handleClose={(event) => { this.handleDeleteProjectDrawerClose(); event.stopPropagation(); }}
                              component={
                                <div>
                                  <div style={{ padding: "10px", textAlign: "center" }}>
                                    <Typography className={classes.title} style={{ fontSize: '1.2rem' }}>Are you sure you want to delete this project?</Typography>

                                  </div>
                                  <div style={{ textAlign: "center", marginTop: "15px", padding: "10px" }}>
                                    <Button variant="contained" style={{ marginRight: "10px" }} onClick={(event) => { this.handleDeleteProjectDrawerClose(); event.stopPropagation(); }}>Cancel</Button>
                                    <Button variant="contained" color="primary" onClick={(event) => { this.deleteProject(); event.stopPropagation(); }}>
                                      Submit
                                    </Button>
                                  </div>
                                </div>
                              }
                            />

                          </div>



                        </Grid>
                      )}
                    </>
                  );


                })}
              </Grid>
            }

            {/* end */}
          </>
        ) : (
          ""
        )}
        {status == "tools" ? (
          <>
            {
              <Grid container className={classes.containerStyle}>


                {this.state.isShowVisualisation === true ?
                  <span>
                    <SubHeader
                      title="Visualisation Tools"
                      hideSearch
                      backButtonAction={() => this.backToTools()}
                    />
                    <Grid style={{ display: "flex" }}>
                      <Grid

                        item
                        xs={12}
                        sm={6}
                        // md={3}
                        style={{
                          padding: 16,

                          // pointerEvents: !ToolUrl[v].is_active && "none",
                        }}
                        className={classes.hoverEffect}
                      >


                        <a
                          href={`${ToolUrl["Visualisation"].url}`}
                          rel="noopener noreferrer"
                          target="_blank"
                          style={{ textDecoration: "none", color: 'black' }}
                        >
                          <div
                            className={classes.paper}
                            style={{
                              minHeight: 150,
                              minWidth: 235,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: 0,
                            }}
                          //onClick={() => this.getToolsVisual()}
                          >
                            <img
                              src="../../assets/IDE_Icons/01_strategic_modeler.svg"
                              //  alt={ToolUrl[v].icon}
                              style={{
                                marginBottom: 10,
                                width: 50,
                                height: 50,
                              }}
                            />
                            <Typography
                              variant="h6"
                              style={{
                                color: "black",
                                textAlign: "center",
                              }}
                            >
                              Data Pivoting
                            </Typography>
                          </div>

                        </a>

                      </Grid>


                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        style={{
                          padding: 16,
                          pointerEvents: "none",
                        }}
                        className={classes.hoverEffect}
                      >


                        <a

                          rel="noopener noreferrer"
                          target="_blank"
                          style={{ textDecoration: "none", color: 'black' }}
                        >
                          <div
                            className={classes.paper}
                            style={{
                              minHeight: 150,
                              minWidth: 235,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: 0,
                            }}
                          //onClick={() => this.getToolsVisual()}
                          >
                            <img
                              src="../../assets/IDE_Icons/02_enterprise_modeler.svg"
                              //  alt={ToolUrl[v].icon}
                              style={{
                                marginBottom: 10,
                                width: 50,
                                height: 50,
                              }}
                            />
                            <Typography
                              variant="h6"
                              style={{
                                color: "grey",
                                textAlign: "center",
                              }}
                            >
                              Graphical Visualization
                            </Typography>
                          </div>

                        </a>

                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        style={{
                          padding: 16,
                          pointerEvents: "none",
                        }}
                        className={classes.hoverEffect}
                      >


                        <a

                          rel="noopener noreferrer"
                          target="_blank"
                          style={{ textDecoration: "none", color: 'black' }}
                        >
                          <div
                            className={classes.paper}
                            style={{
                              minHeight: 150,
                              minWidth: 235,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: 0,
                            }}
                          //onClick={() => this.getToolsVisual()}
                          >
                            <img
                              src="../../assets/IDE_Icons/03_qpas_builder.svg"
                              //  alt={ToolUrl[v].icon}
                              style={{
                                marginBottom: 10,
                                width: 50,
                                height: 50,
                              }}
                            />
                            <Typography
                              variant="h6"
                              style={{
                                color: "grey",
                                textAlign: "center",
                              }}
                            >
                              GIS
                            </Typography>
                          </div>

                        </a>

                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        style={{
                          padding: 16,
                          pointerEvents: "none",
                        }}
                        className={classes.hoverEffect}
                      >


                        <a

                          rel="noopener noreferrer"
                          target="_blank"
                          style={{ textDecoration: "none", color: 'black' }}
                        >
                          <div
                            className={classes.paper}
                            style={{
                              minHeight: 150,
                              minWidth: 235,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: 0,
                            }}
                          //onClick={() => this.getToolsVisual()}
                          >
                            <img
                              src="../../assets/IDE_Icons/04_entity_designer_d360.svg"
                              //  alt={ToolUrl[v].icon}
                              style={{
                                marginBottom: 10,
                                width: 50,
                                height: 50,
                              }}
                            />
                            <Typography
                              variant="h6"
                              style={{
                                color: "grey",
                                textAlign: "center",
                              }}
                            >
                              Dashboard
                            </Typography>
                          </div>

                        </a>

                      </Grid>


                    </Grid>
                  </span>
                  :
                  <span>

                    <SubHeader
                      title="Tools"
                      hideSearch
                      backButtonAction={() => this.backToProject()}
                    />
                    <Grid container className={classes.containerStyle}>
                      {projectToolLists?.map((v) => {
                        console.log("is this tools", v);
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={3}
                            style={{
                              padding: 16,
                              pointerEvents: !ToolUrl[v].is_active && "none",
                            }}
                            className={classes.hoverEffect}
                          >
                            {

                              v === 'Visualisation' ?

                                <a

                                  rel="noopener noreferrer"
                                  target="_blank"
                                  style={{ textDecoration: "none", color: 'black' }}
                                >
                                  <div
                                    className={classes.paper}
                                    style={{
                                      minHeight: 150,
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      marginBottom: 0,
                                    }}
                                    onClick={() => this.getToolsVisual()}
                                  >
                                    <img
                                      src={`../../assets/IDE_Icons/${ToolUrl[v].icon}.svg`}
                                      alt={ToolUrl[v].icon}
                                      style={{
                                        marginBottom: 10,
                                        width: 50,
                                        height: 50,
                                      }}
                                    />
                                    <Typography
                                      variant="h6"
                                      style={{
                                        color: !ToolUrl[v].is_active && "grey",
                                        textAlign: "center",
                                      }}
                                    >
                                      {v}
                                    </Typography>
                                  </div>

                                </a>


                                :


                                <a
                                  href={v === 'Entity Designer (D360)' ?
                                    `${ToolUrl[v].url}?metaDataName=${metaDataName}&metaDataId=${metaDataId}&projectId=${projectId}&clientName=${localStorage.getItem('client_name')}&clientId=${localStorage.getItem('client_id')}&clientDB=${localStorage.getItem('client_db')}&isDB=true`
                                    : v === 'QPAS Builder' ? `${ToolUrl[v].url}?qpasid=${projectId}&metaDataId=${metaDataId}&projectName=${metaDataName}`
                                      : v === 'Strategic Modeler' ? `${ToolUrl[v].url}?projectName=${metaDataName}&metaDataId=${metaDataId}&&StrategicModelerid=${projectId}`
                                        : v === 'Enterprise Modeler' ? `${ToolUrl[v].url}?metaDataName=${metaDataName}&metaDataId=${metaDataId}&projectId=${projectId}&clientName=${localStorage.getItem('client_name')}&clientId=${localStorage.getItem('client_id')}&clientDB=${localStorage.getItem('client_db')}&isDB=false`
                                          : v === 'UI Custom Form Designer' ? `${ToolUrl[v].url}?projectName=${metaDataName}&metaDataId=${metaDataId}&&Modelerid=${projectId}`
                                            : v === 'UI Flow Builder' ? `${ToolUrl[v].url}?projectName=${metaDataName}&metaDataId=${metaDataId}&&Modelerid=${projectId}`
                                              : v === "Message Catalog"
                                                ? `${ToolUrl[v].url}?projectName=${metaDataName}&metaDataId=${metaDataId}&&MessageCatalogid=${projectId}`
                                                : ToolUrl[v].url}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  style={{ textDecoration: "none", color: 'black' }}
                                >
                                  <div
                                    className={classes.paper}
                                    style={{
                                      minHeight: 150,
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      marginBottom: 0,
                                    }}
                                    onClick={() => this.getTools()}
                                  >
                                    <img
                                      src={`../../assets/IDE_Icons/${ToolUrl[v].icon}.svg`}
                                      alt={ToolUrl[v].icon}
                                      style={{
                                        marginBottom: 10,
                                        width: 50,
                                        height: 50,
                                      }}
                                    />
                                    <Typography
                                      variant="h6"
                                      style={{
                                        color: !ToolUrl[v].is_active && "grey",
                                        textAlign: "center",
                                      }}
                                    >
                                      {v}
                                    </Typography>
                                  </div>

                                </a>
                            }

                          </Grid>
                        );
                      })}
                    </Grid>
                  </span>
                }

              </Grid>
            }

            {/* end */}
          </>
        ) : (
          ""
        )}
        {status === "privileges" && (
          <>
            <div style={{ padding: "40px" }}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
                style={{ paddingBottom: "20px" }}
              >
                <Grid item className={classes.flexCenter}>
                  <IconButton onClick={this.backToClient}>
                    <KeyboardBackspace htmlColor="rgba(17, 15, 71, 1)" />
                  </IconButton>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    style={{ fontWeight: "bold" }}
                  >
                    Back
                  </Typography>
                </Grid>
                <Button variant="contained">Save</Button>
              </Grid>
              <Privileges mytools={ToolUrl}></Privileges>
            </div>
          </>
        )}
        {status === "client" && (
          <>
            {/* <div className={classes.addbtn} onClick={() => this.addClient()}>
              {" "}
              <AddIcon /> Add Client
            </div> */}
            <div>
              <TabComponent
                padding={false}
                onTabClick={this.onTabClick}
                showSearch
                buttonName={this.giveMeButtonName()}
                buttonAction={() => this.switchFunction()}
                tabtitles={[
                  {
                    name: "Clients",
                    field: "clients",
                  },
                  {
                    name: "Manage Users",
                    field: "users",
                  },
                  {
                    name: "Manage Roles",
                    field: "roles",
                  },
                  {
                    name: "Masters",
                    field: "masters",
                  },
                ]}
                clients={
                  <Grid container style={{ padding: 16 }}>
                    <Grid
                      container
                      justify="flex-end"
                    >
                      <TextField type="text" label="Search" size='small' variant="outlined" style={{ width: 300, marginBottom: 8 }} placeholder="Search . . ." onChange={(event) => clientsearch(event.target.value)} />
                    </Grid>
                    {searchClientList?.map((v, index) => {
                      return (
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          justify="space-between"
                          className={classes.paper}
                          onClick={() =>
                            this.navProject(
                              v?.clientId,
                              v?.clientname,
                              v?.dbname
                            )
                          }

                        >
                          <div className={classes.flexCenter} >
                            <Avatar
                              aria-label="recipe"
                              className={classes.avatar}
                              style={{
                                marginRight: 15,
                                textTransform: "capitalize",
                                backgroundColor: `${darkColorCli[this.clientColorChanges(index)]
                                  }`,
                              }}
                            >
                              {v?.clientname.slice(0, 1)}
                            </Avatar>
                            <Typography style={{ textAlign: "center" }}>
                              {v?.clientname}
                            </Typography>
                          </div>
                          <div className={classes.flexCenter}>
                            <Button
                              variant="outlined"
                              color="primary"
                              className={classes.buttonView}

                            >
                              <Typography variant="body1">
                                {`${v?.projcount}`}&nbsp;&nbsp; PROJECTS
                              </Typography>
                              <ChevronRightIcon />
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              className={classes.buttonView}
                              style={{ marginLeft: 10 }}
                              onClick={(event) => { this.deleteClientOpen(v?.clientname); event.stopPropagation(); }}
                            >
                              <Typography variant="body1">
                                DELETE
                              </Typography>

                            </Button>


                            <Dialog
                              open={deleteClient}
                              handleClose={(event) => { this.handleDeleteClientDrawerClose(); event.stopPropagation(); }}
                              component={
                                <div>
                                  <div style={{ padding: "10px", textAlign: "center" }}>
                                    <Typography className={classes.title} style={{ fontSize: '1.2rem' }}>Are you sure you want to delete this client?</Typography>

                                  </div>
                                  <div style={{ textAlign: "center", marginTop: "15px", padding: "10px" }}>
                                    <Button variant="contained" style={{ marginRight: "10px" }} onClick={(event) => { this.handleDeleteClientDrawerClose(); event.stopPropagation(); }}>Cancel</Button>
                                    <Button variant="contained" color="primary" onClick={(event) => { this.deleteClient(); event.stopPropagation(); }}>
                                      Submit
                                    </Button>
                                  </div>
                                </div>
                              }
                            />
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                }
                users={
                  <Grid container style={{ padding: 16 }}>
                    {this.state.users?.map((v, index) => {
                      return (
                        <>
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justify="space-between"
                            className={classes.paper}
                          >
                            <div className={classes.flexCenter}>
                              <div>
                                <Typography variant="body1">{v?.username}</Typography>
                                <Typography variant="caption">
                                  {v?.designation}
                                </Typography>
                              </div>
                              <Divider
                                orientation="vertical"
                                variant="middle"
                                flexItem
                                className={classes.divider}
                              ></Divider>
                              <Typography
                                variant="caption"
                                style={{ textAlign: "center" }}
                              >
                                {v?.email}
                              </Typography>
                            </div>
                            <div className={classes.flexCenter}>
                              <Switch
                                name="checkedA"
                                inputProps={{ "aria-label": " checkbox" }}
                              />
                              <Button
                                variant="outlined"
                                color="primary"
                                style={{ margin: "0px 15px" }}
                                className={classes.buttonView}
                              >
                                <Typography variant="body1">Map Role</Typography>
                              </Button>
                              <Tooltip title="Delete">
                                <IconButton aria-label="delete">
                                  <DeleteIcon onClick={() => this.actionDeleteDrawer(v?.id)} />

                                </IconButton>
                              </Tooltip>
                              <MoreVertIcon></MoreVertIcon>
                            </div>
                          </Grid>
                        </>
                      )
                    })}

                  </Grid>
                }
                roles={
                  <Grid container style={{ padding: 16 }}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justify="space-between"
                      className={classes.paper}
                    >
                      <div className={classes.flexCenter}>
                        <Avatar
                          aria-label="recipe"
                          className={classes.avatar}
                          style={{
                            marginRight: 15,
                            textTransform: "capitalize",
                          }}
                        >
                          R
                        </Avatar>
                        <Typography style={{ textAlign: "center" }}>
                          User Roles
                        </Typography>
                      </div>
                      <div className={classes.flexCenter}>
                        <Switch
                          name="checkedA"
                          inputProps={{ "aria-label": " checkbox" }}
                        />
                        <Button
                          variant="outlined"
                          color="primary"
                          className={classes.buttonView}
                          style={{ margin: "0px 15px" }}
                          onClick={this.privileges}
                        >
                          <Typography variant="body1">+ Privileges</Typography>
                        </Button>
                        <MoreVertIcon></MoreVertIcon>
                      </div>
                    </Grid>
                  </Grid>
                }
                masters={<Masters />}
              />
            </div>

            {/* dialog box */}
            {/* <Dialog
              open={addclient}
              handleClose={() => this.addClient()}
              component={
                <div>
                  <Typography className={classes.title}>Add Client</Typography>
                  <div className={classes.dialogbox}>
                    <TextField
                      variant="outlined"
                      placeholder="Client name"
                      label="Client name"
                      onChange={(e) =>
                        this.handleCange("addclientvalue", e.target.value)
                      }
                      fullWidth={true}
                    />
                  </div>
                  <div
                    className={classes.savebtns}
                    onClick={() => this.onSubmitClient()}
                  >
                    {" "}
                    Submit{" "}
                  </div>
                </div>
              }
            /> */}
            {/* <Grid container className={classes.containerStyle}>
              {clientList?.map((v, index) => {
                return (
                  <Grid 
                    container
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                    className={classes.paper}
                    onClick={() =>
                      this.navProject(
                        v?.docclient_vs_db_stg?.client,
                        v?.docclient_vs_db_stg?.client,
                        v?.docclient_vs_db_stg?.db_name
                      )
                    }
                    >
                    <div className={classes.flexCenter}>
                    <Avatar
                        aria-label="recipe"
                        className={classes.avatar}
                        style={{
                          marginRight:15,
                          textTransform:'capitalize',
                        backgroundColor: `${
                            darkColorCli[this.clientColorChanges(index)]
                        }`,
                        }}
                    >
                        {v?.docclient_vs_db_stg?.client.slice(0, 1)}
                    </Avatar>
                    <Typography style={{ textAlign: "center" }}>
                              {v?.docclient_vs_db_stg?.client}
                    </Typography>
                </div>
                <div className={classes.flexCenter}>
                    <Typography variant="body1">{"Projects"}</Typography>
                    <IconButton>
                        <ChevronRightIcon />
                    </IconButton>
                </div>
              </Grid>
                );
              })}
            </Grid> */}
          </>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(withAllContexts(EntityComponent)));