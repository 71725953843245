import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Button, Grid } from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    "& .MuiPaper-elevation4": {
      boxShadow: "none",
    },
    "& .Mui-selected": {
      backgroundColor: "#fff",
      borderRadius: "15px 15px 0 0",
      padding: "20px",
    },
    "& .MuiTab-wrapper": {
      textTransform: "capitalize",
    },
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  containerAlign: {
    padding: "10px 20px",
  },
  btnSpacing: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  btn: {
    textTransform: "none",
    borderRadius: "8px",
  },
  tabsEdit: {
    backgroundColor: "#f4f4f5",
    color: "#000",
  },
  indiactorDisplay: {
    display: "none",
  },
}));

export default function Privileges(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const toolsSet = props.mytools;
  const toolsName = Object.keys(toolsSet);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          classes={{
            indicator: classes.indiactorDisplay,
            root: classes.tabsEdit,
          }}
        >
          <Tab label="Configuration" {...a11yProps(0)} />
          <Tab label="WorkFlow" {...a11yProps(1)} />
          {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Grid container spacing={3}>
          {toolsName.map((v) => (
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-between"
              key={v}
              className={classes.containerAlign}
            >
              <Typography style={{ paddingTop: "3px" }}>{v}</Typography>
              <div className={classes.flexCenter}></div>
              <div className={classes.btnSpacing}>
                <Button variant="contained" classes={{ root: classes.btn }}>
                  Create
                </Button>
                <Button variant="contained" classes={{ root: classes.btn }}>
                  Read
                </Button>
                <Button variant="contained" classes={{ root: classes.btn }}>
                  Update
                </Button>
                <Button variant="contained" classes={{ root: classes.btn }}>
                  Delete
                </Button>
              </div>
            </Grid>
          ))}
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        Properties not assigned yet
      </TabPanel>
    </div>
  );
}
