import React from "react";
import { themes, alertProps,DrawerProps } from "../utils";

export const ThemeContext = React.createContext({
    name: themes.default,
    setTheme: () => null,
});

export const AuthContext = React.createContext({
    user: {},
    setAuth: () => null,
});

export const AlertContext = React.createContext({
    open: false,
    severity: alertProps.severity.success,
    msg: "",
    vertical: alertProps.vertical.top,
    horizontal: alertProps.horizontal.center,
    onclose: () => null,
    setSnack: () => null,
});

export const EdittablesContext = React.createContext({
    data: null,
    setData: () => null,
});

export const BalancePayloadContext = React.createContext({
    payload: {},
    setPayload: () => null,
});

/**
 * DrawerContext store the props of the Drawer component, which is provided at the /src/App.js using 
 * the /src/App.drawer.js
 */
 export const DrawerContext = React.createContext({
    open: true,
    direction: DrawerProps.direction.right,
    variant: DrawerProps.variant.temporary,
    onClose: () => null,
    setDrawer: () => null
  })

  export const LanguageContext = React.createContext({
    // setLanguages: () => null
  })